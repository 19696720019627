import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import { doc, addDoc, getDoc, updateDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import { getAuth, updatePassword, EmailAuthProvider } from "firebase/auth";
import { generateKey } from '../utils';
import { connect } from 'react-redux';


const ImageUploaderProfile = (props) => {
    // Create a root reference
    const storage = getStorage();
    const auth = getAuth();
    const db = getFirestore();
    const { image, onChange } = props;
    const user = auth.currentUser;
    const NEWS_CARD_WITH_IMAGE = 'newsletter_image';
    const [file, setFile] = useState(image ? image : '');
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length) {
                const extension = acceptedFiles[0].name.split('.').pop();
                const newName = generateKey();
                uploadFile(acceptedFiles[0], `${newName}.${extension}`);
            }
        },
    });

    useEffect(() => {
        onChange(file);
    }, [file]);

    const uploadFile = (currentFile, name) => {
        // Create a reference to 'images/mountains.jpg'
        const storageRef = ref(storage, `images/${name}`);
        // While the file names are the same, the references point to different files
        const uploadTask = uploadBytesResumable(storageRef, currentFile);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.log(error);
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFile(downloadURL);
                });
            }
        );
    };

    const deleteFile = async () => {
        const storageRef = ref(storage, file);
        console.log(user);
        const docRef = collection(db, "Users");
        const q = query(docRef, where('uid', "==", user.uid));
        let data = await getDocs(q);
        data.forEach((v, i) => {
            console.log(v.data());
            let updatedUser = {
                _2fa: v.data()._2fa,
                company: v.data().company,
                email: v.data().email,
                logo: '',
                name: v.data().name,
                responsibility: v.data().responsibility,
                uid: v.data().uid
            };
            setDoc(doc(db, "Users", v.id), updatedUser).then(() => {
                console.log('logo removed');
                // Delete the file
                deleteObject(storageRef)
                    .then(() => {
                        setFile('');
                        props.setUser(updatedUser)
                    })
                    .catch((error) => {
                        // Uh-oh, an error occurred!
                    });
            });
        })




    };

    return (
        <Grid
            container
            spacing={0}
            style={{ minHeight: 175 }}
            alignContent="center"
            justifyContent="flex-end"
            alignItems="center"
            flexDirection="column-reverse"
        >
            {Boolean(file) && (
                <Grid item xs={12}>
                    <div style={{ position: 'absolute' }}>
                        <IconButton varaint="outlined" color="error" onClick={deleteFile}>
                            <Delete />
                        </IconButton>
                    </div>
                    <img src={file} style={{ width: '175px', height: '175px', borderRadius: '100px' }} className={NEWS_CARD_WITH_IMAGE} />
                </Grid>
            )}
            {!Boolean(file) && (
                <Grid item xs={12} {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Button fullWidth color="inherit" variant="outlined" style={{ height: 175, width: 175, borderRadius: '100px' }}>
                        قم/قومي بسحب وإسقاط الصورة هنا ، أو انقر/ي لتحديد الصورة
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
const mapStateToProps = (state) => {
    return state
}
const dispatchUser = (dispatch) => {
    return {
        setUser: (updatedUser) => { dispatch({ type: 'UPDATE_USER', value: updatedUser }) }
    }
}
export default connect(mapStateToProps, dispatchUser)(ImageUploaderProfile);
// export default ImageUploaderProfile;
