import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
// Style
import { Container } from '@mui/material';
// Components
import LoadingContainer from '../containers/LoadingContainer';
// Pages

import Dashboard from '../pages/Dashboard/Dashboard';
import Categories from '../pages/Dashboard/Categories';
import Home from '../pages/Public/Home';
import ErrorPage from '../pages/Public/ErrorPage';
import Newsletters from '../pages/Dashboard/Newsletters';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';

import Login from '../pages/Auth/Login';
import About from '../pages/Public/About';
import Contact from '../pages/Public/Contact';
import ManageUsers from '../pages/Dashboard/ManageUsers';
import Landing from '../pages/Public/Landing';
import Unsubscribe from '../pages/Public/Unsubscribe';
import Newsletter from '../pages/Public/Newsletter';
import NewsWeather from '../pages/Dashboard/NewsWeather';
import NewsWithImage from '../pages/Dashboard/NewsWithImage';
import NewsStocks from '../pages/Dashboard/NewsStocks';
import NewsCurrency from '../pages/Dashboard/NewsCurrency';
import NewsImage from '../pages/Dashboard/NewsImage';
import NewsLibrary from '../pages/Dashboard/NewsLibrary';
import Subscribers from '../pages/Dashboard/Subscribers';
import SponsorList from '../pages/Sponsor/SponsorList';
import SponsorAdd from '../pages/Sponsor/SponsorAdd';
import NewsTwitter from '../pages/Dashboard/NewsTwitter';
import NewsFacebook from '../pages/Dashboard/NewsFacebook';
import NewsInstagram from '../pages/Dashboard/NewsInstagram';
import NewsYoutube from '../pages/Dashboard/NewsYoutube';
import CreateNewsletter from '../pages/Dashboard/CreateNewsletter';
import Settings from '../pages/Dashboard/Settings';
import Statistics from '../pages/Dashboard/Statistics';
import NewslettersArchive from '../pages/Dashboard/NewslettersArchive';
import NewsletterArchiveDetails from '../pages/Dashboard/NewsletterArchiveDetails';
import Profile from '../pages/Dashboard/Profile';
import Users from '../pages/Dashboard/Users';
import ForgetPassword from '../pages/Auth/ForgetPassword';
import Companies from '../pages/Admin/Companies';

import Subscribe from '../iframes/Subscribe';
import { connect } from 'react-redux';

import NewsPDF from '../pages/Dashboard/NewsPDF';
import Signup from '../pages/Signup/Signup';
import Verify2FA from '../pages/Auth/Verify2FA';
import TermsAndPolicies from '../pages/Public/TermsAndPolicies';
import SubscribersIntegration from '../components/SubscribersIntegration';

import ConfirmResetPasswordAndEmailVerification from '../pages/Auth/ConfirmResetPasswordAndEmailVerification';
import ChatGPT from '../pages/Dashboard/ChatGPT';

const Router = (props) => {
	const { status } = useSigninCheck();
	
	return (
		<LoadingContainer status={status}>
			<BrowserRouter>
				<Switch>
					{/* DASHBOARD ROUTES */}
					<PrivateRoute path="/dashboard">
						<Dashboard />
					</PrivateRoute>
					<PrivateRoute path="/profile">
						<Profile />
					</PrivateRoute>
					<PrivateRoute path="/categories">
						<Categories />
					</PrivateRoute>
					<PrivateRoute path="/newsweather">
						<NewsWeather />
					</PrivateRoute>
					<PrivateRoute path="/newswithimage">
						<NewsWithImage />
					</PrivateRoute>
					<PrivateRoute path="/chatgpt">
						<ChatGPT />
					</PrivateRoute>
					<PrivateRoute path="/newsstocks">
						<NewsStocks />
					</PrivateRoute>
					<PrivateRoute path="/newscurrency">
						<NewsCurrency />
					</PrivateRoute>
					<PrivateRoute path="/newsimage">
						<NewsImage />
					</PrivateRoute>
					<PrivateRoute path="/newspdf">
						<NewsPDF />
					</PrivateRoute>
					<PrivateRoute path="/newslibrary">
						<NewsLibrary />
					</PrivateRoute>
					<PrivateRoute path="/newstwitter">
						<NewsTwitter />
					</PrivateRoute>
					<PrivateRoute path="/newsfacebook">
						<NewsFacebook />
					</PrivateRoute>
					<PrivateRoute path="/newsinstagram">
						<NewsInstagram />
					</PrivateRoute>
					<PrivateRoute path="/newsyoutube">
						<NewsYoutube />
					</PrivateRoute>
					<PrivateRoute path="/settings">
						<Settings />
					</PrivateRoute>
					<PrivateRoute path="/statistics">
						<Statistics />
					</PrivateRoute>
					<PrivateRoute path="/createnewsletter">
						<CreateNewsletter />
					</PrivateRoute>
					<PrivateRoute path="/subscribers">
						<Subscribers />
					</PrivateRoute>
					<PrivateRoute path="/archive">
						<NewslettersArchive />
					</PrivateRoute>
					<PrivateRoute path="/newsletter/:id" exact>
						<NewsletterArchiveDetails />
					</PrivateRoute>
					<PrivateRoute path="/sponsor/list">
						<SponsorList />
					</PrivateRoute>
					<PrivateRoute path="/sponsor/add">
						<SponsorAdd />
					</PrivateRoute>
					<PrivateRoute path="/users">
						<Users />
					</PrivateRoute>

					{/*  Admin  */}
					<PrivateRoute path="/admin/companies">
						<Companies />
					</PrivateRoute>

					{/*  Admin  */}


					{/* <PrivateRoute path="/manage-users">
						<ManageUsers />
					</PrivateRoute> */}
					{/* END OF DASHBOARD ROUTES */}
					{/* AUTH ROUTES */}
					<Route path="/terms-policies" exact>
						<TermsAndPolicies />
					</Route>
					<Route path="/subscribe/:company" exact>
						<SubscribersIntegration />
					</Route>
					<ProtectedRoute path="/login">
						<Login />
					</ProtectedRoute>
					<ProtectedRoute path="/forget_password">
						<ForgetPassword />
					</ProtectedRoute>
					<ProtectedRoute path="/auth/action">
						<ConfirmResetPasswordAndEmailVerification />
					</ProtectedRoute>
					<ProtectedRoute path="/signup">
						<Signup />
					</ProtectedRoute>
					<ProtectedRoute path="/verify-2fa">
						<Verify2FA />
					</ProtectedRoute>
					{/* END OF AUTH ROUTES */}
					{/* <Route path="/archive">
						<Newsletters />
					</Route>
					<Route path="/about">
						<About />
					</Route> */}

					{/* <Route path="/categories">
						<Categories />
					</Route>


					<Route path="/contact">
						<Contact />
					</Route> */}
					{/* <Route path="/newsletters/:id" exact>
						<Newsletter />
					</Route>
					
					<Route path="/:section?/:mb?" exact>
						<Landing />
					</Route>  */}
					<Route path="/unsubscribe/:company/:email" exact>
						<Unsubscribe />
					</Route>
					<Route path="/" exact>
						<Home />
					</Route>
					<Route path="/subscribe-to-newsletter/:company" exact>
						<Subscribe />
					</Route>
					<Route path="*">
						<ErrorPage />
					</Route>
				</Switch>
			</BrowserRouter>
		</LoadingContainer>
	);
};


const mapStateToProps = (state) => {
	return state
}


export default connect(mapStateToProps)(Router);
