import {
    Autocomplete,
    Button,
    Card,
    Snackbar,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import RichTextEditor from '../../atoms/RichTextEditor';
import { makeStyles } from "tss-react/mui";
import HTMLViewer from '../../components/HTMLViewer';
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';


const WeatherRow = ({ onChange, onDelete, id, item }) => {
    const [weather, setWeather] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                low: 0,
                high: 0,
            }
    );

    useEffect(() => {
        onChange(weather);
    }, [weather]);

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
                options={[
                    '☁️',
                    '☂️',
                    '☔',
                    '🌁',
                    '❄️',
                    '🌫️',
                    '🌨️',
                    '🌧️',
                    '⛈️',
                    '🌩️',
                    '🌪️',
                    '⛅',
                    '☀️',
                    '🥵',
                    '🥶',
                    '💨',
                ]}
                disableClearable
                onChange={(e, icon) => setWeather({ ...weather, icon })}
                renderInput={(params) => <TextField {...params} label="الرمز" />}
            />
            <TextField
                fullWidth
                label="المدينة"
                placeholder="المدينة"
                variant="outlined"
                value={weather.name}
                onChange={({ target: { value } }) => setWeather({ ...weather, name: value })}
            />
            <TextField
                label="الحرارة القصوى"
                placeholder="الحرارة القصوى"
                fullWidth
                variant="outlined"
                value={weather.high}
                onChange={({ target: { value } }) => setWeather({ ...weather, high: value })}
            />
            <TextField
                label="أدنى درجة حرارة"
                placeholder="أدنى درجة حرارة"
                fullWidth
                variant="outlined"
                value={weather.low}
                onChange={({ target: { value } }) => setWeather({ ...weather, low: value })}
            />
            <IconButton color='primary' onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
};



const NewsWeather = (props) => {
    const [weather, setWeather] = useState(
        [
            {
                key: generateKey(),
                name: '',
                icon: '',
                low: 0,
                high: 0,
            },
        ]
    );
    const previewCompRef = useRef();
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [body, setBody] = useState('');
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            weatherContainer: {
                background: '#fbfbfb',
                padding: '20px',
                borderRadius: '25px',
                boxShadow: '0px 0px 5px #eee',
            },
            content: {
				background: '#fff',
				borderRadius: '25px',
				padding: '25px',
			}

        })
    );
    const db = getFirestore();
    const { company, data } = props;
    const { classes } = useStyles();

    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };

    const saveWeatherNews = async () => {
        const docTitle = getDateId(company);
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: {
                    id: generateKey(),
                    items: weather,
                    body: body,
                    category: 'توقعات الطقس',
                    title: 'الطقس',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth()+1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                Image: data.data().Image,
                Currency: data.data().Currency,
                data: data.data().data
            }).then((data) => {
                console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: [],
                Stocks: '',
                Weather: {
                    id: generateKey(),
                    items: weather,
                    body: body,
                    category: 'توقعات الطقس',
                    title: 'الطقس',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth()+1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                Image: [],
                Currency: '',
                data: {
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth()+1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }
    const previewWeatherNews = () => {
        // console.log(weather);
        setIntro(body);
        setItems(prev => [prev, items]);
        console.log(items, intro, weather);
    }
    const PreviewFn = () => {
        setItems([{
            items: weather,
            body: body,
            category: 'توقعات الطقس',
            title: 'الطقس',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);
    return (
        <>
            <Grid container spacing={4} mt={1} style={{marginRight:0, width: '100%'}}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">توقعات الطقس</Typography>
                        <Stack direction="column" divider={<Divider />} spacing={2}>
                            {weather.map((city, index) => (
                                <WeatherRow
                                    key={city.key}
                                    id={city.key}
                                    onChange={(newCity) => {
                                        let temp = [...weather];
                                        temp.splice(index, 1, newCity);
                                        setWeather(temp);
                                    }}
                                    item={city}
                                    onDelete={() => {
                                        let temp = [...weather];
                                        temp.splice(index, 1);
                                        setWeather(temp);
                                    }}
                                />
                            ))}
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() =>
                                setWeather([
                                    ...weather,
                                    {
                                        key: generateKey(),
                                        name: '',
                                        icon: '',
                                        low: 0,
                                        high: 0,
                                    },
                                ])
                            }
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                        <RichTextEditor defaultValue={body} onChange={setBody} />
                    </Stack>
                </Grid>
                <Grid item xs={6} style={{paddingTop: 0}}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button size='small' variant="outlined" endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                        <Button size='small' variant="contained" endIcon={<SaveIcon />} onClick={saveWeatherNews}>حفظ في مكتبة الفئات</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
				open={snackBar.isOpen}
				autoHideDuration={6000}
				onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
				message={snackBar.message}
			/>
        </>
    )


}
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(NewsWeather);