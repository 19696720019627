import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
// import { makeStyles } from "tss-react/mui";
import { makeStyles } from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const PackagesPlan = ({ plan, selectedPlanCode, handleSelectPlan }) => {
    console.log(selectedPlanCode)
    const useStyles = makeStyles({
        selected: {
            border: "1px solid #f27021 !important",
            background: '#f27021 !important',
            color: '#fff !important',
            '& p': {
                color: '#fff'
            },
            '& svg': {
                color: '#fff'
            }
        },
        text: {
            color: '#f27021'
        },
        cost: {

        },
        currency: {
            fontSize: '20px',
            verticalAlign: 'super'
        },
        noOfEmails: {
            fontSize: '20px',
        },
        emails: {
            fontSize: '20px',
        }
    });

    const classes = useStyles();
    return (
        // <Card className={classes.selected}
        <Card className={plan.code === selectedPlanCode ? classes.selected : null}
            style={{
                boxShadow: 'none',
                background: '#fff',
                border: '1px solid #f27021',
                color: '#f27021'
            }}
            onClick={() => handleSelectPlan(plan)}>
            <CardActionArea>
                <CardContent>
                    {/* <Typography gutterBottom variant="h5" component="div">
                        {plan.name}
                    </Typography> */}
                    {plan.type === 'flexible' &&
                        <>
                            <Typography variant="body2" color="text.secondary">
                                <AddCircleRoundedIcon color='primary' fontSize="14px" /><span>{plan.fixedCost}$/شهر</span>
                            </Typography>
                            <Typography variant="body2" className={classes.text}>
                                <span>{plan.costPerEmails.toFixed(2)}$/{plan.numberOfEmails}بريد إلكتروني</span>
                                {/* <CheckCircleIcon fontSize='14px' color='primary' /> */}
                            </Typography>
                            <Typography variant="body2" className={classes.text}>
                                <CheckCircleIcon fontSize='14px' color='primary' />
                                حتى {plan.MaxTotalEmails} بريد إلكتروني شهري
                            </Typography>
                        </>}
                    {plan.type === 'fixed' &&
                        <>
                            <Typography variant="h5" component="div">
                                <span className={classes.cost}>{plan.costPerEmails}</span>
                                <span className={classes.currency}>$</span>
                                <span>/</span>
                                <span className={classes.noOfEmails}>{plan.numberOfEmails} </span>
                                <span className={classes.emails}>بريد إلكتروني</span>
                                {/* <CheckCircleIcon fontSize='14px' color='primary' /> */}
                            </Typography>
                            <Typography variant="body2" className={classes.text}>
                                <CheckCircleIcon fontSize='14px' color='primary' />
                                حتى {plan.MaxTotalEmails} بريد إلكتروني شهري
                            </Typography>
                        </>}

                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default PackagesPlan;