import React, { useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Grid,
    Paper,
    TextField,
    Typography,
    Divider,
    Snackbar,
    Checkbox,
    Autocomplete,
    IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ImageUploader from '../../components/ImageUploader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from "tss-react/mui";
import { ChromePicker } from 'react-color';
import { doc, addDoc, deleteDoc, getDoc, documentId, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { connect } from 'react-redux';
import PackagesPlan from '../../components/PackagesPlans';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia, dark } from "react-syntax-highlighter/dist/esm/styles/prism";

const Settings = (props) => {
    const { id, data, loggedInUser } = props;

    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [isCopiedOne, setIsCopiedOne] = useState(false);
    const [isCopiedTwo, setIsCopiedTwo] = useState(false);
    const [isCopiedThree, setIsCopiedThree] = useState(false);

    const codeSnippetJS = `<script src="https://firebasestorage.googleapis.com/v0/b/mujaz-platform.appspot.com/o/subscriptionJs%2Fsubscription-form.js?alt=media&token=afe5098e-d5bb-4389-a553-49946c339002"></script>`;
    const codeSnippetHTML = `<div class="subscribe-emails"></div>`;
    const codeSnippetFn = `<script>injectSubscriptionForm('subscribe-emails', '${data?.personalProfile?.companyData?.company}');</script>`;



    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [sender, setSender] = useState('');
    const [senderEmail, setSenderEmail] = useState('');

    const [awsSES, setAwsSES] = useState('');
    const [awsCW, setAwsCW] = useState('');

    const [toPreview, setToPreview] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFF');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [logo, setLogo] = useState('');
    const [footerLogo, setFooterLogo] = useState('');
    const [footerText, setFooterText] = useState('');
    const [footerSlogonText, setFooterSlogonText] = useState('');
    const [footerCopyrightText, setFooterCopyrightText] = useState('');
    const [footerUnsubscribeText, setFooterUnsubscribeText] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');

    const [_package, setPackage] = useState(
        {
            type: 'تجريبية',
            active: 'نشط'
        }
    );
    const plans = [
        {
            type: 'flexible',
            name: 'حزمة مرنة'
        },
        {
            type: 'fixed',
            name: 'حزمة ثابتة'
        }
    ]
    const PlanPackages = [
        {
            type: 'flexible',
            code: 'flexible',
            name: 'حزمة مرنة',
            fixedCost: 25,
            costPerEmails: 0.7,
            numberOfEmails: 1000,
            MaxTotalEmails: 50000,
        },
        {
            type: 'fixed',
            code: 'fixed1',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 46,
            numberOfEmails: 50000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed2',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 63,
            numberOfEmails: 100000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed3',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 97,
            numberOfEmails: 200000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed4',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 144,
            numberOfEmails: 350000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed5',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 184,
            numberOfEmails: 500000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed6',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 324,
            numberOfEmails: 1000000,
            MaxTotalEmails: 1000000,
        },
    ]

    const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] = useState(false);
    const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] = useState(false);

    const [openUpgradePlan, setOpenUpgradePlan] = useState(false);

    const [selectedPlanByUserToUpgrade, setSelectedPlanByUserToUpgrade] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedPlanCode, setSelectedPlanCode] = useState('');
    const handleSelectPlan = (plan) => {
        console.log(plan.code)
        setSelectedPlanCode(plan.code);
        setSelectedPlanByUserToUpgrade(plan);
    };

    const filteredPlanPackages = PlanPackages.filter((item) =>
        selectedPlan ? item.type === selectedPlan.type : false
    );
    const accountData =
    {
        personalProfile: {
            personalData:
            {
                username: username,
                name: name,
                email: email
            },
            companyData:
            {
                company: company,
                website: website,
                address: address,
                city: city,
                country: country,
            }
        },
        settings:
        {
            senderSettings:
            {
                name: sender,
                email: senderEmail,
                toPreview: toPreview,
                defaultFrom: props?.data?.settings?.senderSettings?.defaultFrom || sender + ' <' + senderEmail + '>',
                defaultReplyTo: props?.data?.settings?.senderSettings?.defaultReplyTo || senderEmail,
                aws: {
                    ses: awsSES,
                    cw: awsCW
                }
            },
            sponsorShip: {
                enabled: props?.data?.settings?.sponsorShip?.enabled,
                type: props?.data?.settings?.sponsorShip?.type
            },
            view:
            {
                logo: logo,
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
            },
            footer:
            {
                footerLogo: footerLogo,
                text: footerText,
                slogon: footerSlogonText,
                copyrightText: footerCopyrightText,
                unsubscribeText: footerUnsubscribeText,
                socialMedia:
                {
                    facebook: facebook,
                    instagram: instagram,
                    twitter: twitter,
                    youtube: youtube
                }
            },
            package: {
                type: props?.data?.settings?.package?.type,
                active: props?.data?.settings?.package?.active,
                sentEmails: props?.data?.settings?.package?.sentEmails,
                totalEmails: props?.data?.settings?.package?.totalEmails
            }
        }

    }
    const updatedUserData = {
        company: loggedInUser?.company,
        email: loggedInUser?.email,
        logo: loggedInUser?.logo,
        name: loggedInUser?.name,
        responsibility: loggedInUser?.responsibility,
        uid: loggedInUser?.uid,
        _2fa: {
            enabled: loggedInUser?._2fa?.enabled,
            secret: loggedInUser?._2fa?.secret
        }
    };
    const [tabValue, setTabValue] = React.useState('الملف الشخصي');
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;



    const handleCopyOne = () => {
        setIsCopiedOne(true);
        setTimeout(() => setIsCopiedOne(false), 2000);
    };
    const handleCopyTwo = () => {
        setIsCopiedTwo(true);
        setTimeout(() => setIsCopiedTwo(false), 2000);
    };
    const handleCopyThree = () => {
        setIsCopiedThree(true);
        setTimeout(() => setIsCopiedThree(false), 2000);
    };

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '20px 30px',
                marginBottom: 15,
                fontWeight: 400
            },
            TabList: {
                display: 'flex',
                justifyContent: 'space-around',
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 25px',
                borderRadius: '30px',
                ':hover': {
                    background: '#FFF',
                    color: '#f63',
                }
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '25px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '5px 5px 5px #ccc',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },

            confirmBtnCancel: {
                // color: '#ff6633',
            },
            btnSecondary: {
                borderRadius: 30,
                backgroundColor: '#fff',
                color: '#ff6633',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            },
            saveBtn: {
                padding: '5px 40px'
            },
            // submitBtn: {
            //     background: '#f27021 !important',
            //     color: '#FFF !important',
            //     fontFamily: 'Tajawal, sans-serif !important',
            //     borderRadius: '10px'
            // },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },

        })
    );

    const { classes } = useStyles();

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const handlePrimaryColorClick = () => {
        setDisplayPrimaryColorPicker(displayColorPicker => !displayColorPicker);
    };

    const handlePrimaryColorClose = () => {
        setDisplayPrimaryColorPicker(false);
    };
    const handleSecondaryColorClick = () => {
        setDisplaySecondaryColorPicker(displayColorPicker => !displayColorPicker);
    };

    const handleSecondaryColorClose = () => {
        setDisplaySecondaryColorPicker(false);
    };

    const savePersonal = async () => {
        console.log(accountData);
        await setDoc(doc(db, "Companies", id), accountData).then(() => {
            props.setData(accountData);
            console.log('settings updated');
            setSnackBar({
                isOpen: true,
                message: 'تم حفظ الإعدادات بنجاح',
            });
        });
    }
    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    const getDetails = () => {

        setName(data?.personalProfile?.personalData?.name);
        setUsername(data?.personalProfile?.personalData?.username);
        setAddress(data?.personalProfile?.companyData?.address);
        setCity(data?.personalProfile?.companyData?.city);
        setCountry(data?.personalProfile?.companyData?.country);
        setWebsite(data?.personalProfile?.companyData?.website);
        setCompany(data?.personalProfile?.companyData?.company);

        setSender(data?.settings?.senderSettings?.name);
        setSenderEmail(data?.settings?.senderSettings?.email);
        setToPreview(data?.settings?.senderSettings?.toPreview);
        setAwsSES(data?.settings?.senderSettings?.aws?.ses);
        setAwsCW(data?.settings?.senderSettings?.aws?.cw);

        setPrimaryColor(data?.settings?.view?.primaryColor);
        setSecondaryColor(data?.settings?.view?.secondaryColor);
        setLogo(data?.settings?.view?.logo);
        setFooterLogo(data?.settings?.footer?.footerLogo);
        setFooterText(data?.settings?.footer?.text);
        setFooterSlogonText(data?.settings?.footer?.slogon);
        setFooterCopyrightText(data?.settings?.footer?.copyrightText);
        setFooterUnsubscribeText(data?.settings?.footer?.unsubscribeText);
        setFacebook(data?.settings?.footer?.socialMedia?.facebook);
        setInstagram(data?.settings?.footer?.socialMedia?.instagram);
        setTwitter(data?.settings?.footer?.socialMedia?.twitter);
        setYoutube(data?.settings?.footer?.socialMedia?.youtube);

        setPackage({
            type: data?.settings?.package?.type == 'free' ? 'تجريبية' : 'غير تجريبية',
            active: data?.settings?.package?.active ? 'نشط' : 'غير نشط',
            sentEmails: data?.settings?.package?.sentEmails,
            totalEmails: data?.settings?.package?.totalEmails
        })
    };
    const handleUpgradePlan = () => {
        setOpenUpgradePlan(false);
        console.log(selectedPlanByUserToUpgrade);
    }
    const handleCloseUpgradePlan = () => {
        setOpenUpgradePlan(false);
    }
    useEffect(() => {
        getDetails();
    }, [company, id]);
   


    return (
        <>

            <Grid container>
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paperList}>
                        <Grid item xs={9}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                إعدادات الحساب
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {/* <Stack sx={{ width: '100%', typography: 'body1' }}> */}
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTabValue} aria-label="lab API tabs example" centered className={classes.TabList}>
                                <Tab label="الملف الشخصي" value="الملف الشخصي" />
                                <Tab label="إعدادات النشرة" value="إعدادات النشرة" />
                                <Tab label="الرصيد والدفع" value="الرصيد والدفع" />
                                <Tab label="الربط والتضمين" value="الربط والتضمين" />
                            </TabList>
                        </Box>
                        <TabPanel value="الملف الشخصي" style={{ padding: '24px 0' }}>
                            <Paper elevation={0} style={{ padding: '30px' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>المستخدم الرئيسي</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} mt={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={username}
                                            variant="outlined"
                                            label="البريد الإلكتروني/إسم المستخدم"
                                            margin="normal"
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={name}
                                            onChange={({ target: { value } }) => setName(value)}
                                            variant="outlined"
                                            label="الإسم"
                                            margin="normal"
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>معلومات الشركة</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={6} mt={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={company}
                                            // onChange={({ target: { value } }) => setCompany(value)}
                                            variant="outlined"
                                            label="إسم الشركة/المنطمة"
                                            margin="normal"
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={website}
                                            onChange={({ target: { value } }) => setWebsite(value)}
                                            variant="outlined"
                                            label="الموقع الإلكتروني"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            value={address}
                                            onChange={({ target: { value } }) => setAddress(value)}
                                            variant="outlined"
                                            label="العنوان"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={city}
                                            onChange={({ target: { value } }) => setCity(value)}
                                            variant="outlined"
                                            label="المدينة"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={country}
                                            onChange={({ target: { value } }) => setCountry(value)}
                                            variant="outlined"
                                            label="الدولة"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                <Grid container mt={4}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button size='small' variant="contained" onClick={savePersonal}>حفظ</Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </TabPanel>
                        <TabPanel value="إعدادات النشرة" style={{ padding: '24px 0' }}>
                            <Paper elevation={0} style={{ padding: '30px' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>إعدادات المرسل</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} mt={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={sender}
                                            onChange={({ target: { value } }) => setSender(value)}
                                            variant="outlined"
                                            label="اسم المرسل"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={senderEmail}
                                            onChange={({ target: { value } }) => setSenderEmail(value)}
                                            variant="outlined"
                                            label="البريد الإلكتروني للمرسل"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={toPreview}
                                                onChange={() => setToPreview(value => !value)}
                                            />
                                        } label="عرض اسم المرسل في خانة to عند المستلم" />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                <Grid container spacing={4} mt={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>المظهر</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} style={{ justifyContent: 'space-evenly' }}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6">شعار الشركة</Typography>
                                        <Typography variant="p">سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي.</Typography>
                                        <ImageUploader image={logo} onChange={setLogo} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6">شعار التذييل</Typography>
                                        <Typography variant="p">سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي.</Typography>
                                        <ImageUploader background={"#eee"} image={footerLogo} onChange={setFooterLogo} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} mt={2}>
                                    <Grid item xs={3}>
                                        <Typography variant="h6">اللون الرئيسي</Typography>
                                        <Button style={{ background: primaryColor?.hex, width: '150px', height: '40px' }} size="large" variant="outlined" onClick={handlePrimaryColorClick}>  </Button>
                                        {displayPrimaryColorPicker ? <div style={popover}>
                                            <div style={cover} onClick={handlePrimaryColorClose} />
                                            <ChromePicker
                                                style={{ direction: 'ltr' }}
                                                color={primaryColor}
                                                onChange={(color) => setPrimaryColor(color)} />
                                        </div> : null}

                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="h6">اللون الثانوي</Typography>
                                        <Button style={{ background: secondaryColor?.hex, width: '150px', height: '40px' }} size="large" variant="outlined" onClick={handleSecondaryColorClick}>  </Button>
                                        {displaySecondaryColorPicker ? <div style={popover}>
                                            <div style={cover} onClick={handleSecondaryColorClose} />
                                            <ChromePicker
                                                style={{ direction: 'ltr' }}
                                                color={secondaryColor}
                                                onChange={(color) => setSecondaryColor(color)} />
                                        </div> : null}

                                    </Grid>

                                </Grid>
                                <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>التذييل</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={footerText}
                                            onChange={({ target: { value } }) => setFooterText(value)}
                                            variant="outlined"
                                            label="نص التذييل"
                                            margin="normal"
                                            multiline
                                            rows={2}
                                            maxRows={6}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={footerSlogonText}
                                            onChange={({ target: { value } }) => setFooterSlogonText(value)}
                                            variant="outlined"
                                            label="نص شعار التذييل"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={footerCopyrightText}
                                            onChange={({ target: { value } }) => setFooterCopyrightText(value)}
                                            variant="outlined"
                                            label="نص الحقوق"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={footerUnsubscribeText}
                                            onChange={({ target: { value } }) => setFooterUnsubscribeText(value)}
                                            variant="outlined"
                                            label="نص إلغاء الإشتراك"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={4} mt={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">التواصل الإجتماعي</Typography>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={facebook}
                                            onChange={({ target: { value } }) => setFacebook(value)}
                                            variant="outlined"
                                            label="فايسبوك"
                                            margin="normal"
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={instagram}
                                            onChange={({ target: { value } }) => setInstagram(value)}
                                            variant="outlined"
                                            label="إنستاغرام"
                                            margin="normal"
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={twitter}
                                            onChange={({ target: { value } }) => setTwitter(value)}
                                            variant="outlined"
                                            label="تويتر"
                                            margin="normal"
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={youtube}
                                            onChange={({ target: { value } }) => setYoutube(value)}
                                            variant="outlined"
                                            label="يوتيوب"
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                <Grid container mt={4}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button size='small' variant="contained" onClick={savePersonal}>حفظ</Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </TabPanel>
                        <TabPanel value="الرصيد والدفع" style={{ padding: '24px 0' }}>
                            <Paper elevation={0} style={{ padding: '30px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 2 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>الباقة</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 2 }}>
                                                    <Grid item xs={1} sm={1} md={2}>
                                                        <Typography variant='body1'>نوع الباقة: {_package.type}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={2}>
                                                        <Typography variant='body1'>الحالة: {_package.active}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={2}>
                                                        <Typography variant='body1'>عدد الإيميلات المرسلة: {_package.sentEmails}/{_package.totalEmails}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 2 }} mt={1}>
                                                    <Grid item xs={1} sm={1} md={2}>
                                                        <Button variant='contained' color='primary' onClick={() => setOpenUpgradePlan(true)}>ترقية الباقة</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Dialog open={openUpgradePlan} onClose={handleCloseUpgradePlan} maxWidth='xl'>
                                <DialogTitle>إختر الباقة المناسبة</DialogTitle>
                                <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={12}>
                                            <Autocomplete
                                                disablePortal
                                                size='small'
                                                id="plans"
                                                loading={true}
                                                options={plans}
                                                onChange={(event, value) => { setSelectedPlan(value) }}
                                                getOptionLabel={option => option.name}
                                                renderInput={(params) => <TextField {...params} label="إختر الحزمة" />}
                                            />
                                        </Grid>
                                        <Divider variant="middle" style={{
                                            width: '100%',
                                            marginTop: '25px'
                                        }} />
                                        <Grid item xs={1} sm={1} md={12}>
                                            <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                {filteredPlanPackages.map((plan, index) => {
                                                    return (
                                                        <Grid item xs={1} sm={1} md={4} key={index} style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'space-around',
                                                            alignItems: 'center',
                                                            marginTop: '25px'
                                                        }}>
                                                            <PackagesPlan selectedPlanCode={selectedPlanCode} handleSelectPlan={handleSelectPlan} plan={plan} key={index}/>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Divider component="div" />
                                        <Grid item xs={1} sm={1} md={12}>
                                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                                <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={handleCloseUpgradePlan}> تراجع </Button>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Button variant="contained" className={classes.submitBtn} size="small" onClick={handleUpgradePlan}> ترقية </Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Dialog>
                        </TabPanel>
                        <TabPanel value="الربط والتضمين" style={{ padding: '24px 0' }}>
                            <Paper elevation={0} style={{ padding: '30px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 2 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>رمز الربط والتضمين</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div>

                                                    <CopyToClipboard text={codeSnippetJS} onCopy={handleCopyOne}>
                                                        <IconButton aria-label="delete" color='primary' size='small'>
                                                            {isCopiedOne ? <ContentCopyRoundedIcon fontSize='inherit' /> : <ContentCopyIcon fontSize="inherit" />}
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                    <span>{'<head> ... </head>'}</span>
                                                    <SyntaxHighlighter language="html" style={okaidia} customStyle={{ fontSize: '0.8em' }}>
                                                        {codeSnippetJS}
                                                    </SyntaxHighlighter>
                                                </div>
                                                <div>
                                                    <CopyToClipboard text={codeSnippetJS} onCopy={handleCopyTwo}>
                                                        <IconButton aria-label="delete" color='primary' size='small'>
                                                            {isCopiedTwo ? <ContentCopyRoundedIcon fontSize='inherit' /> : <ContentCopyIcon fontSize="inherit" />}
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                    <span>{'<body> ...'}</span>
                                                    <SyntaxHighlighter language="html" style={okaidia} customStyle={{ fontSize: '0.8em' }}>
                                                        {codeSnippetHTML}
                                                    </SyntaxHighlighter>
                                                </div>
                                                <div>
                                                    <CopyToClipboard text={codeSnippetJS} onCopy={handleCopyThree}>
                                                        <IconButton aria-label="delete" color='primary' size='small'>
                                                            {isCopiedThree ? <ContentCopyRoundedIcon fontSize='inherit' /> : <ContentCopyIcon fontSize="inherit" />}
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                    <span>{' ... </body>'}</span>
                                                    <SyntaxHighlighter language="html" style={okaidia} customStyle={{ fontSize: '0.8em' }}>
                                                        {codeSnippetFn}
                                                    </SyntaxHighlighter>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </TabPanel>
                    </TabContext>
                    {/* </Stack> */}
                </Grid>
            </Grid >
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
};


const mapStateToProps = (state) => {
    console.log(state);
    return state
}
const dispatchData = (dispatch) => {
    return {
        setData: (data) => { dispatch({ type: 'SET_DATA', data: data }) },
        updateUser: (userdata) => dispatch({ type: 'UPDATE_USER', value: userdata })
    }
}
export default connect(mapStateToProps, dispatchData)(Settings);
