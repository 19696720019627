import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Link,
    TextField,
    Typography,
    Snackbar,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Chart from 'react-apexcharts'
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { startOfDay, endOfDay } from 'date-fns';
import { makeStyles } from "tss-react/mui";
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';
import moment from 'moment/moment';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#f27021',
    },
}));

const Dashboard = (props) => {
    const history = useHistory();
    let from = new Date();
    from.setDate(from.getDate() - 7);

    const [fromDate, setFromDate] = useState(from);
    const [toDate, setToDate] = useState(new Date());

    const [isLoading, setIsLoading] = useState(true);
    const { company, id, data } = props;
    const [todayClicked, setTodayClicked] = useState(0);
    const [todayDelivered, setTodayDelivered] = useState(0);
    const [todayOpened, setTodayOpened] = useState(0);
    const [todayEmailTotal, setTodayEmailTotal] = useState(0);

    const [subscribers, setSubscribers] = useState(0);
    const [newSubscribers, setNewSubscribers] = useState(0);
    const [newUnSubscribers, setNewUnSubscribers] = useState(0);
    const [subscribersPercentage, setSubscribersPercentage] = useState(0);
    const [unSubscribersPercentage, setUnSubscribersPercentage] = useState(0);
    const [loadingSubsribers, setLoadingSubsribers] = useState(true);

    const [logo, setLogo] = useState(data?.settings?.view?.logo);
    const db = getFirestore();
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '50px'
                // marginTop: 210,
            }

        })
    );
    const [_package, setPackage] = useState(
        {
            type: 'تجريبية',
            active: 'نشط',
            sentEmails: 0,
            totalEmails: 0
        }
    );
    const [series, setSeries] = useState([
        {
            name: 'النشرات المرسلة',
            data: []
        },
        {
            name: 'النشرات المستلمة',
            data: []
        },
        {
            name: 'النشرات التي تم فتحها',
            data: []
        },
        {
            name: 'النقرات',
            data: []
        },
    ])
    const [graphOptions, setGraphOptions] = useState({
        series: series,
        options: {
            chart: {
                height: 150,
                type: 'area',
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                itemMargin: {
                    horizontal: 15,
                    vertical: 30
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: []
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },

        },
    });
    const { classes } = useStyles();
    AWS.config.update({
        accessKeyId: data?.settings?.senderSettings?.aws?.cw?.AccessKey?.AccessKeyId,
        secretAccessKey: data?.settings?.senderSettings?.aws?.cw?.AccessKey?.SecretAccessKey,
        region: 'us-east-1' // replace with your region
    });
    console.log(data?.settings?.senderSettings?.aws);
    const ses = new AWS.CloudWatch();
    const convert_to_iso_Date = (date, type) => {
        date.setHours(0, 0, 0, 0);
        if (type == 'to') {
            date.setHours(23, 59, 59, 0);
        }
        return date.toISOString();
    }
    const todayRep = () => {
        let from = convert_to_iso_Date(new Date(), 'from');
        let to = convert_to_iso_Date(new Date(), 'to');
        const paramsSend = {
            Namespace: 'AWS/SES',
            MetricName: 'Send',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsDelivered = {
            Namespace: 'AWS/SES',
            MetricName: 'Delivery',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsOpened = {
            Namespace: 'AWS/SES',
            MetricName: 'Open',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsClicked = {
            Namespace: 'AWS/SES',
            MetricName: 'Click',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        let emailsSent = 0;
        let emailsDelivered = 0;
        let emailsOpened = 0;
        let emailsClicked = 0;
        let dateCat = 0;
        ses.getMetricStatistics(paramsSend, (err, sendData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (sendData?.Datapoints[0]?.Sum) {
                    emailsSent = sendData.Datapoints[0].Sum;
                    setTodayEmailTotal(emailsSent);
                } else {
                    setTodayEmailTotal(0);
                }

            }
        });

        ses.getMetricStatistics(paramsDelivered, (err, deliveredData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (deliveredData?.Datapoints[0]?.Sum) {
                    emailsDelivered = deliveredData.Datapoints[0].Sum;
                    setTodayDelivered(emailsDelivered);
                } else {
                    setTodayDelivered(0);
                }

            }
        })
        ses.getMetricStatistics(paramsOpened, (err, openedData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (openedData?.Datapoints[0]?.Sum) {
                    emailsOpened = openedData.Datapoints[0].Sum;
                    setTodayOpened(emailsOpened);
                } else {
                    setTodayOpened(0);
                }

            }
        })
        ses.getMetricStatistics(paramsClicked, (err, clickedData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (clickedData?.Datapoints[0]?.Sum) {
                    emailsClicked = clickedData?.Datapoints[0]?.Sum;
                    setTodayClicked(emailsClicked);
                } else {
                    setTodayClicked(0);
                }
            }
        })


    }
    function getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment.utc(startDate); // use utc() to set start date to GMT
        var stopDate = moment.utc(stopDate); // use utc() to set stop date to GMT
        while (currentDate <= stopDate) {
            dateArray.push(currentDate.format('YYYY-MM-DD')); // format date in GMT
            currentDate = currentDate.add(1, 'days');
        }
        const isoStrings = dateArray.map(date => moment.utc(date).toISOString()); // convert dates to ISO strings in GMT
        return isoStrings;
    }
    const fetchStatisticsData = (from, to) => {
        let diff = getDates(from, to);
        let _from = convert_to_iso_Date(from, 'from');
        let _to = convert_to_iso_Date(to, 'to');
        const paramsSend = {
            Namespace: 'AWS/SES',
            MetricName: 'Send',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsDelivered = {
            Namespace: 'AWS/SES',
            MetricName: 'Delivery',
            StartTime: _from,
            EndTime: _to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsOpened = {
            Namespace: 'AWS/SES',
            MetricName: 'Open',
            StartTime: _from,
            EndTime: _to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsClicked = {
            Namespace: 'AWS/SES',
            MetricName: 'Click',
            StartTime: _from,
            EndTime: _to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        ses.getMetricStatistics(paramsSend, (err, sendData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                sendData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestamp asc
                let emailsSent = [];
                let emailsDelivered = [];
                let emailsOpened = [];
                let emailsClicked = [];
                let added = false;
                console.log(sendData);
                if(sendData.Datapoints.length > 0){
                    for (let i = 0; i < diff.length; i++) {
                        let date = new Date(diff[i]);
                        let offset = date.getTimezoneOffset();
                        let adjustedOffset = sendData?.Datapoints[0]?.Timestamp?.getTimezoneOffset();
                        let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                        added = false;
                        for (let y = 0; y < sendData.Datapoints.length; y++) {
                            let _dataDate = ((new Date(sendData.Datapoints[y].Timestamp).getTime()) - (new Date(sendData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                            if (_dataDate === adjustedDate) {
                                emailsSent.push(sendData.Datapoints[y].Sum);
                                added = true;
                            }
                        }
                        if (!added) {
                            emailsSent.push(0);
                        }
                    }
                    ses.getMetricStatistics(paramsDelivered, (err, deliveredData) => {
                        if (err) {
                            console.log(err, err.stack);
                        } else {
                            deliveredData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestap asc
                            for (let i = 0; i < diff.length; i++) {
                                let date = new Date(diff[i]);
                                let offset = date.getTimezoneOffset();
                                let adjustedOffset = deliveredData.Datapoints[0].Timestamp.getTimezoneOffset();
                                let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                                added = false;
                                for (let y = 0; y < deliveredData.Datapoints.length; y++) {
                                    let _dataDate = ((new Date(deliveredData.Datapoints[y].Timestamp).getTime()) - (new Date(deliveredData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                                    if (_dataDate === adjustedDate) {
                                        emailsDelivered.push(deliveredData.Datapoints[y].Sum);
                                        added = true;
                                    }
                                }
                                if (!added) {
                                    emailsDelivered.push(0);
                                }
                            }
                            ses.getMetricStatistics(paramsOpened, (err, openedData) => {
                                if (err) {
                                    console.log(err, err.stack);
                                } else {
                                    openedData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestap asc
                                    for (let i = 0; i < diff.length; i++) {
                                        let date = new Date(diff[i]);
                                        let offset = date.getTimezoneOffset();
                                        let adjustedOffset = openedData.Datapoints[0].Timestamp.getTimezoneOffset();
                                        let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                                        added = false;
                                        for (let y = 0; y < openedData.Datapoints.length; y++) {
                                            let _dataDate = ((new Date(openedData.Datapoints[y].Timestamp).getTime()) - (new Date(openedData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                                            if (_dataDate === adjustedDate) {
                                                emailsOpened.push(openedData.Datapoints[y].Sum);
                                                added = true;
                                            }
                                        }
                                        if (!added) {
                                            emailsOpened.push(0);
                                        }
                                    }
                                    ses.getMetricStatistics(paramsClicked, (err, clickedData) => {
                                        if (err) {
                                            console.log(err, err.stack);
                                        } else {
                                            clickedData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestap asc
                                            for (let i = 0; i < diff.length; i++) {
                                                let date = new Date(diff[i]);
                                                let offset = date.getTimezoneOffset();
                                                if (clickedData.Datapoints.length > 0) {
                                                    let adjustedOffset = clickedData.Datapoints[0].Timestamp.getTimezoneOffset();
                                                    let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                                                    added = false;
                                                    for (let y = 0; y < clickedData.Datapoints.length; y++) {
                                                        let _dataDate = ((new Date(clickedData.Datapoints[y].Timestamp).getTime()) - (new Date(clickedData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                                                        if (_dataDate === adjustedDate) {
                                                            emailsClicked.push(clickedData.Datapoints[y].Sum);
                                                            added = true;
                                                        }
                                                    }
                                                    if (!added) {
                                                        emailsClicked.push(0);
                                                    }
                                                } else {
                                                    emailsClicked.push(0);
                                                }
                                            }
                                            //update usestate graphOptions
                                            setGraphOptions({
                                                ...graphOptions,
                                                series: [
                                                    {
                                                        name: 'النشرات المرسلة',
                                                        data: emailsSent
                                                    },
                                                    {
                                                        name: 'النشرات المستلمة',
                                                        data: emailsDelivered
                                                    },
                                                    {
                                                        name: 'النشرات التي تم فتحها',
                                                        data: emailsOpened
                                                    },
                                                    {
                                                        name: 'النقرات',
                                                        data: emailsClicked
                                                    },
                                                ],
                                                options: {
                                                    ...graphOptions.options,
                                                    xaxis: {
                                                        ...graphOptions.options.xaxis,
                                                        categories: diff
                                                    }
                                                }
                                            });
                                            //update usestate graphOptions
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
                
            }
        });
    }
    const getNumOfSubscribers = async () => {
        const subRef = collection(db, "Subscribers");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        setSubscribers(data.size);
    }
    const newSubscribersFn = async () => {
        const today = new Date();
        const startTimestamp = Timestamp.fromDate(startOfDay(today));
        const endTimestamp = Timestamp.fromDate(endOfDay(today));

        let newUsers = query(collection(db, 'Subscribers'), where("company", "==", company), where('createdAt', '>=', startTimestamp), where('createdAt', '<=', endTimestamp));
        let allUsers = query(collection(db, 'Subscribers'), where("company", "==", company));
        let queryNewUsers = await getDocs(newUsers);
        let queryAllUsers = await getDocs(allUsers);
        setNewSubscribers(queryNewUsers.docs.length);
        let percentage = 0;
        if (queryNewUsers.docs.length == 0 || queryAllUsers.docs.length == 0) {
            percentage = 0;
        } else {
            percentage = (queryNewUsers.docs.length / queryAllUsers.docs.length) * 100;
        }
        setSubscribersPercentage(percentage.toFixed(2));
        setLoadingSubsribers(false);
    }
    const newUnSubscribersFn = async () => {
        const today = new Date();
        const startTimestamp = Timestamp.fromDate(startOfDay(today));
        const endTimestamp = Timestamp.fromDate(endOfDay(today));

        let newUnsubscribers = query(collection(db, 'Unsubscribers'), where("company", "==", company), where('createdAt', '>=', startTimestamp), where('createdAt', '<=', endTimestamp));
        let allUsers = query(collection(db, 'Subscribers'), where("company", "==", company));
        let queryNewUsers = await getDocs(newUnsubscribers);
        let queryAllUsers = await getDocs(allUsers);
        setNewUnSubscribers(queryNewUsers.docs.length);
        let percentage = 0;
        if (queryNewUsers.docs.length == 0 || queryAllUsers.docs.length == 0) {
            percentage = 0;
        } else {
            percentage = (queryNewUsers.docs.length / queryAllUsers.docs.length) * 100;
        }
        setUnSubscribersPercentage(percentage.toFixed(2));
        setLoadingSubsribers(false);
    }
    useEffect(() => {
        todayRep();
        setIsLoading(false);
        getNumOfSubscribers();
        newSubscribersFn();
        newUnSubscribersFn();
        fetchStatisticsData(fromDate, toDate);
        setPackage({
            type: data?.settings?.package?.type == 'free' ? 'تجريبية' : 'غير تجريبية',
            active: data?.settings?.package?.active ? 'نشط' : 'غير نشط',
            sentEmails: data?.settings?.package?.sentEmails,
            totalEmails: data?.settings?.package?.totalEmails
        })
    }, [company, id]);

    

    return (
        <>
            {/* {props.loggedInUser.responsibility != 'superadmin' &&
                <Paper elevation={0} className={classes.paper}>
                    <Typography mb={4} variant="h3" align="center" className={classes.welomeText}>
                        مرحبا بك في  {company}
                    </Typography>
                    <img src={logo} width={300}/>
                </Paper>
            } */}
            <Grid container spacing={4} mt={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item xs={1} sm={1} md={5}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={12}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>الباقة</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <Typography variant='body1'>نوع الباقة: {_package.type}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <Typography variant='body1'>الحالة: {_package.active}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }} mt={2}>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <BorderLinearProgress variant="determinate" value={(_package.sentEmails / _package.totalEmails) * 100} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <Typography variant='body1' style={{ textAlign: 'left' }}>{_package.sentEmails}/{_package.totalEmails}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={5}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={10.5}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>المرسلة اليوم</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1.5}>
                                <Link
                                    style={{ color: 'black' }}
                                    component="button"
                                    onClick={() => {
                                        history.push('/statistics');
                                    }}
                                >
                                    <MoreVertIcon />
                                </Link>

                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المرسلة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayEmailTotal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المستلمة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayDelivered}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>تم الفتح</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayOpened}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">النقرات</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayClicked}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">إلغاء الإشتراك</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">البريد المزعج</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={9.5}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>المشتركين</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={2.5}>
                                <Link
                                    style={{ color: 'black' }}
                                    component="button"
                                    onClick={() => {
                                        history.push('/subscribers');
                                    }}
                                >
                                    <MoreVertIcon />
                                </Link>

                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2">عدد المشتركين</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1">{subscribers} مشترك</Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid container spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }} mt={3}>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={8}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                            <Grid item xs={1} sm={1} md={8}>
                                                                {newUnSubscribers > 0 ?
                                                                    <Typography variant="body2" color='red' style={{
                                                                        fontSize: '0.75rem',
                                                                        textAlign: 'left',
                                                                        lineHeight: 1.4,
                                                                        paddingLeft: '0.3rem',
                                                                        paddingTop: '6px'
                                                                    }}>{unSubscribersPercentage}%-</Typography>
                                                                    : newSubscribers > 0 ?
                                                                        <Typography variant="body2" color='green' style={{
                                                                            fontSize: '0.75rem',
                                                                            textAlign: 'left',
                                                                            lineHeight: 1.4,
                                                                            paddingLeft: '0.3rem',
                                                                            paddingTop: '6px'
                                                                        }}>{subscribersPercentage}%+</Typography>
                                                                        : loadingSubsribers ? <CircularProgress /> : ''}

                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={4}>
                                                                {newUnSubscribers > 0 ?
                                                                    <Typography variant="h6" style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newUnSubscribers}</Typography>
                                                                    : newSubscribers > 0 ?
                                                                        <Typography variant="h6" style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newSubscribers}</Typography>
                                                                        : ''}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        {newUnSubscribers > 0 ?
                                                            <Typography style={{ fontSize: '0.70rem', textAlign: 'right' }} variant="body2">إلغاء الإشتراك</Typography>
                                                            : newSubscribers > 0 ?
                                                                <Typography style={{ fontSize: '0.70rem', textAlign: 'right' }} variant="body2">مشترك جديد</Typography>
                                                                : ''}

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {newUnSubscribers > 0 ? <ArrowDownwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} /> : newSubscribers > 0 ? <ArrowUpwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} /> : ''}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={1} sm={1} md={6}>
                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={8}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                            <Grid item xs={1} sm={1} md={8}>
                                                                <Typography variant="body2" color='green' style={{
                                                                    fontSize: '0.75rem',
                                                                    textAlign: 'left',
                                                                    lineHeight: 1.4,
                                                                    paddingTop: '6px'
                                                                }}>{subscribersPercentage}%+</Typography>
                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={4}>
                                                                <Typography variant="h6" align='center' style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newSubscribers}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Typography style={{ fontSize: '0.80rem', textAlign: 'left', paddingLeft: '15px' }} variant="body2" align='center'>مشترك جديد</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ArrowUpwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={4} mt={1} mb={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item md={12}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} mt={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={12}>
                                <Chart
                                    options={graphOptions.options}
                                    series={graphOptions.series}
                                    type="area"
                                    height={350}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Dashboard);
