import { Grid, Snackbar, TextField, Button, Paper, Typography } from '@mui/material';
import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import IntroCard from '../../components/newsCards/IntroCard';
import { makeStyles } from "tss-react/mui";
import { ThemeProvider, useTheme } from '@mui/material';
import { doc, addDoc, documentId, getDoc, deleteDoc, collection, getFirestore, updateDoc, getDocs, setDoc, query, where } from 'firebase/firestore';
import ScheduleDateTime from '../../components/ScheduleDateTime';
import { confirm } from "react-confirm-box";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { generateHtmlView, generateWrappedEmail } from '../../utils';
import Preview from '../../components/Preview';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { styled, alpha } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { TodayOutlined } from '@material-ui/icons';

const NewsletterSendStatus = ({ totalSubscribers, sentCount, isActive }) => {
    const percentage = Math.floor((sentCount / totalSubscribers) * 100);
    const useStyles = makeStyles()(
        () => ({
            newsletterOverlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 9999
            },
            newsletterSpinnerContainer: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            },
            newsletterText: {
                margin: '10px 0',
                color: 'white',
                fontSize: '16px'
            }

        })
    );

    const { classes } = useStyles();
    if (!isActive) {
        return null;
    }

    return (
        <div className={classes.newsletterOverlay}>
            <div className={classes.newsletterSpinnerContainer}>
                <p className={classes.newsletterText}>يتم إرسال النشرة...</p>
                <CircularProgress style={{ color: '#f27021' }} />
                <p className={classes.newsletterText}>{`تم إرسال ${sentCount} من أصل ${totalSubscribers} (${percentage}%)`}</p>
            </div>
        </div>
    );
};

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const CreateNewsletter = (props) => {

    const [items, setItems] = useState([]);
    const [subject, setSubject] = useState('');
    const [intro, setIntro] = useState('');
    const [completedDraggedItems, setCompletedDraggedItems] = useState([]);
    const [testEmail, setTestEmail] = useState('');
    const [scheduleDateTime, setScheduleDateTime] = useState(new Date());
    const sponsorType = ['أساسي', 'ثانوي'];
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [primarySponsor, setPrimarySponsor] = useState({});
    const [secondarySponsor, setSecondarySponsor] = useState({});
    const previewCompRef = useRef();
    const db = getFirestore();

    //loader variables
    const [sentCount, setSentCount] = useState(0);
    const [totalSubscribers, setTotalSubscribers] = useState(0);
    const [isActive, setIsActive] = useState(false);
    //loader variables

    //menu (group buttons) to send newsletter
    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const openMenu = Boolean(anchorElMenu);
    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };
    //menu (group buttons) to send newsletter
    //testEmail box to send newsletter
    const [openTestEmail, setOpenTestEmail] = useState(false);
    const handleClickTestEmail = () => {
        setOpenTestEmail(true);
    };
    const handleCloseTestEmail = () => {
        setOpenTestEmail(false);
    };
    //testEmail box to send newsletter

    const { company, data, compData } = props;
    let logo = data?.settings?.view?.logo;
    let primaryColor = data?.settings?.view?.primaryColor?.hex;
    let senderEmail = data?.settings?.senderSettings?.email;
    let footer = data?.settings?.footer;
    let website = data?.personalProfile?.companyData?.website;
    // let smtp_connection = data.settings.senderSettings.smtp_connection;
    let SecretAccessKey = data?.settings?.senderSettings?.aws?.ses?.AccessKey?.SecretAccessKey;
    let AccessKeyId = data?.settings?.senderSettings?.aws?.ses?.AccessKey?.AccessKeyId;
    let defaultFrom = data?.settings?.senderSettings?.defaultFrom;
    let defaultReplyTo = data?.settings?.senderSettings?.defaultReplyTo;

    const theme = useTheme();
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                width: 250,
                height: 250
            },
            cardBtns: {
                boxSizing: 'border-box',
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
            },
            editBtn: {
                background: '#f63',
                color: '#FFF',
                width: '100px',
                borderRadius: '30px',
            },
            deleteBtn: {
                width: '100px',
                borderRadius: '30px',
                marginLeft: '20px',
                background: '#FFF',
                color: '#f63',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }
            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '25px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '5px 5px 5px #ccc',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            confirmBtnConfirm: {

            },
            confirmBtnCancel: {
                // color: '#ff6633',
            },
            whiteBtn: {
                margin: '0 10px',
                color: '#ff7400',
                background: '#FFF',
                boxShadow: '0px 3px 6px #00000026',
                borderRadius: 20
            },
            orangeBtn: {
                margin: '0 10px',
                background: '#ff7400',
                color: '#FFF',
                boxShadow: '0px 3px 6px #00000026',
                borderRadius: 20
            },
            btnSecondary: {
                borderRadius: 30,
                backgroundColor: '#fff',
                color: '#ff6633',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            },
            preview: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
        })
    );

    const { classes } = useStyles();
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    // const options = {
    //     render: (message, onConfirm, onCancel) => {
    //         return (
    //             <Grid container className={classes.confirmContainer}>
    //                 <Grid item className={classes.confirmText}>
    //                     <Typography variant="h5">{message}</Typography>
    //                 </Grid>
    //                 {/* <Grid item>
    //                     <ScheduleDateTime handleScheduleDateTimeChange={handleScheduleDateTimeChange} />
    //                 </Grid> */}
    //                 <Grid item className={classes.confirmAction}>
    //                     <Button variant="contained" className={classes.confirmBtnConfirm} onClick={onCancel}> تراجع </Button>
    //                     <Button variant="contained" className={classes.confirmBtnCancel} onClick={onConfirm}> تأكيد </Button>
    //                 </Grid>



    //             </Grid>
    //         );
    //     }
    // };

    const handleScheduleDateTimeChange = (value) => {
        setScheduleDateTime(value);
        // const scheduleDateTime = value;
        console.log(value);

    }

    const getData = async (completedDraggedItems) => {
        const dataRef = collection(db, "NewsLibrary");
        const q = query(dataRef, where('data.company', "==", company));
        let data = await getDocs(q);
        let _array = [];
        data.forEach((doc) => {
            // console.log(doc.data());
            if (doc.data().Currency?.id) {
                _array.push(doc.data().Currency);
                // setItems(items => [...items, doc.data().Currency])
            }
            if (doc.data().Stocks?.id) {
                _array.push(doc.data().Stocks);
                // setItems(items => [...items, doc.data().Stocks]);
            }
            if (doc.data().Image) {
                doc.data().Image.map((value, index) => {
                    _array.push(value);
                    // setItems(items => [...items, value]);
                })
                // _array.push(doc.data().Image);
                // setItems(items => [...items, doc.data().Image]);
            }
            if (doc.data().Weather?.id) {
                _array.push(doc.data().Weather);
                // setItems(items => [...items, doc.data().Weather]);
            }
            if (doc.data().News) {
                doc.data().News.map((value, index) => {
                    _array.push(value);
                    // setItems(items => [...items, value]);
                })
            }
            if (doc.data().Instagram?.post?.id) {
                _array.push(doc.data().Instagram);
            }
            if (doc.data().Facebook?.post?.id) {
                _array.push(doc.data().Facebook);
            }
        });
        const filteredItem = _array.filter((elem) => !completedDraggedItems.find(({ id }) => elem.id === id));
        setItems(items => filteredItem);
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        let add;
        let active = items;
        let complete = completedDraggedItems;
        // Source Logic
        if (source.droppableId === "Items") {
            add = active[source.index];
            active.splice(source.index, 1);
        } else {
            add = complete[source.index];
            complete.splice(source.index, 1);
        }

        // Destination Logic
        if (destination.droppableId === "Items") {
            active.splice(destination.index, 0, add);
        } else {
            complete.splice(destination.index, 0, add);
        }
        setCompletedDraggedItems(complete);
        setItems(active);
    }
    const getDateId = (company) => {
        return `${company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };
    const saveDraft = async () => {
        let docTitle = getDateId(company);
        await setDoc(doc(db, 'Newsletters', docTitle), {
            items: completedDraggedItems,
            schedule: '',
            subject: subject,
            text: intro,
            sent: 'no',
            html: generateWrappedEmail(generateHtmlView(completedDraggedItems, intro, logo, primaryColor, website, footer, senderEmail, null, company, docTitle, primarySponsor, secondarySponsor), subject, intro, primaryColor)
        });
        setSnackBar({
            isOpen: true,
            message: 'تم حفظ المسودة بنجاح',
        });
    }
    const getSavedDraft = async () => {
        let docTitle = getDateId(company);
        let data = await getDoc(doc(db, 'Newsletters', docTitle));
        if (data.data()) {
            setCompletedDraggedItems(data.data().items);
            setIntro(data.data().text);
            setSubject(data.data().subject);
        }

    }

    const getSponsor = async () => {
        const today = new Date();
        const dayOfWeek = today.toLocaleDateString('en-US', { weekday: 'long' });
        const sponsorRef = collection(db, "Sponsors");
        const q = query(sponsorRef, where("company", "==", company));
        let data = await getDocs(q);
        if (data.size > 0) {
            data.forEach((doc) => {
                let sponsor = doc.data();
                const isTodayIncluded = sponsor.pattern.some(day => day.value === dayOfWeek);
                if ((sponsor.dateFrom.toDate() <= today) && (sponsor.dateTo.toDate() >= today) && (isTodayIncluded)) {
                    sponsor.type == sponsorType[0] ? setPrimarySponsor(sponsor) : setSecondarySponsor(sponsor);
                }
            });
        }

    }

    const saveToArchive = async (docTitle) => {
        await setDoc(doc(db, 'NewslettersArchive', docTitle), {
            items: completedDraggedItems,
            subject: subject,
            text: intro,
            hide: false,
            html: generateWrappedEmail(generateHtmlView(completedDraggedItems, intro, logo, primaryColor, website, footer, senderEmail, null, company, docTitle, primarySponsor, secondarySponsor), subject, intro, primaryColor),
        });
        removeItemsFromLibrary();
    }

    const scheduleEmail = async () => {
        const result = await confirm("الرجاء تحديد وقت جدولة النشرة", options);
        console.log(scheduleDateTime);
        if (result) {
            let docTitle = getDateId(company);
            await setDoc(doc(db, 'Newsletters', docTitle), {
                items: completedDraggedItems,
                schedule: scheduleDateTime,
                subject: subject,
                text: intro,
                sent: 'no',
                html: generateWrappedEmail(generateHtmlView(completedDraggedItems, intro, logo, primaryColor), subject, intro),
            });
            saveToArchive(docTitle);

        }
    };
    const sendEmail = async () => {
        // const internetConnectionSpeed = navigator.connection.downlink <= 0.5 ? true : false;
        // if (internetConnectionSpeed) {
        //     setSnackBar({
        //         isOpen: true,
        //         message: 'اتصال الإنترنت بطيء جدًا، يرجى المحاولة لاحقًا أو الاتصال بشبكة أخرى',
        //     });
        //     return;
        // }
        let docTitle = getDateId(company);
        let emails = [];
        const result = await confirm("هل تريد إرسال النشرة؟", options)
        if (result) {
            const querySnapshot = await getDocs(query(collection(db, 'Subscribers'), where('company', '==', company)));
            setTotalSubscribers(querySnapshot.size);
            const canSendEmail = checkQuotaEmailSendingAvailability(querySnapshot.size);
            if (canSendEmail) {
                setIsActive(true);
                querySnapshot.forEach((item, index) => {
                    emails.push(item.data().email);
                });
                for (let i = 0; i < emails.length; i++) {
                    let html = generateWrappedEmail(generateHtmlView(completedDraggedItems, intro, logo, primaryColor, website, footer, senderEmail, emails[i], company, docTitle, primarySponsor, secondarySponsor), subject, intro, primaryColor);
                    await addDoc(collection(db, 'Mails'), {
                        company: company,
                        toUids: [emails[i]],
                        defaultFrom: defaultFrom,
                        defaultReplyTo: defaultReplyTo,
                        message: {
                            subject,
                            html,
                        },
                    });
                    setSentCount(i + 1);
                }
                setSnackBar({
                    isOpen: true,
                    message: 'تم إرسال النشرة بنجاح',
                });
                setIsActive(false);
                addNumberOfEmailsSentToQuota(emails.length);
                removeItemsFromLibrary();
                saveToArchive(docTitle);
            }
        }
    }

    const addNumberOfEmailsSentToQuota = async (number) => {

        const companySettings = query(collection(db, "Companies"), where("personalProfile.companyData.company", "==", company));
        const querySnapshot = await getDocs(companySettings);
        querySnapshot.forEach(async (documentSnapshot) => {
            const documentRef = doc(db, 'Companies', documentSnapshot.id);
            const documentData = documentSnapshot.data();

            const currentValue = documentData.settings?.package?.sentEmails || 0; // Use 0 as the default value if KeyToUpdate doesn't exist
            const newValue = currentValue + number;
            console.log(currentValue);
            console.log(number);
            console.log(newValue);
            // Update the specific key in the document
            const updatedData = {
                ...documentData,
                settings: {
                    ...documentData.settings,
                    package: {
                        ...documentData.settings?.package,
                        sentEmails: newValue
                    }
                }
            };
            await updateDoc(documentRef, updatedData)
                .then(() => {
                    props.updateQuota(newValue);
                });
            console.log('Document updated successfully');
        });
    }

    const checkQuotaEmailSendingAvailability = (number) => {
        if (data?.settings?.package?.sentEmails + number > data?.settings?.package?.totalEmails) {
            setSnackBar({
                isOpen: true,
                message: 'لا يتوفر عدد كافي من الرسائل الإلكترونية لإتمام المطلوب',
            });
            return false;
        }
        return true;
    }
    const sendTestEmailPopUp = async () => {
        handleClickTestEmail();
    }
    const sendTestEmail = async () => {
        let uid = '';

        if (testEmail == '') {
            return;
        }
        const canSendEmail = checkQuotaEmailSendingAvailability(1);
        if (canSendEmail) {
            const users = query(collection(db, "Users"), where("email", "==", testEmail));
            const querySnapshot = await getDocs(users);
            querySnapshot.forEach((doc) => {
                if (doc.data()) {
                    uid = doc.id;
                }
            });
            if (uid == '') {
                await addDoc(collection(db, 'Users'), {
                    company: company,
                    name: '',
                    email: testEmail
                }).then(d => {
                    uid = d.id;
                })
            }
            let html = generateWrappedEmail(generateHtmlView(completedDraggedItems, intro, logo, primaryColor, website, footer, senderEmail, uid, company, getDateId(company), primarySponsor, secondarySponsor), subject, intro, primaryColor);
            await addDoc(collection(db, 'Mails'), {
                company: company,
                // toUids: [uid],
                toUids: [testEmail],
                // smtp_connection: smtp_connection,

                AccessKeyId: AccessKeyId,
                SecretAccessKey: SecretAccessKey,

                defaultFrom: defaultFrom,
                defaultReplyTo: defaultReplyTo,
                message: {
                    subject,
                    html,
                },
            }).then(() => {
                addNumberOfEmailsSentToQuota(1);
            });
            setSnackBar({
                isOpen: true,
                message: 'تم إرسال نشرة التجريبية بنجاح',
            });
        }

    }
    const cleanNewsLibrary = async () => {
        const dataRef = collection(db, "NewsLibrary");
        const q = query(dataRef, where('data.company', "==", company));
        let _data = await getDocs(q);
        _data.forEach(async (_doc, index) => {
            let data = _doc.data();
            if (data) {
                if (data.Currency == ""
                    && data.Image.length < 1
                    && data.News.length < 1
                    && data.Stocks == ""
                    && data.Weather == "") {
                    //delete record
                    await deleteDoc(doc(dataRef, _doc.id));
                }
            }
        })
    }
    const removeItemsFromLibrary = async () => {
        console.log(completedDraggedItems);
        const dataRef = collection(db, "NewsLibrary");
        const q = query(dataRef, where('data.company', "==", company));
        let _data = await getDocs(q);
        _data.forEach((_doc, index) => {
            let data = _doc.data();
            completedDraggedItems.forEach((selected, i) => {
                if (data) {
                    if (data.Currency?.id == selected.id) {
                        data.Currency = '';
                    } else if (data.Image.length > 0) {
                        data.Image.forEach((image, __index) => {
                            if (image.id == selected.id) {
                                data.image.splice(__index, 1);
                            }
                        })
                        data.Image = '';
                    } else if (data.Stocks?.id == selected.id) {
                        data.Stocks = '';
                        // console.log('selected stocks');
                    } else if (data.Weather?.id == selected.id) {
                        data.Weather = '';
                    } else if (data.News.length > 0) {
                        data.News.forEach((_news, _index) => {
                            if (_news.id == selected.id) {
                                data.News.splice(_index, 1);
                                // console.log('selected news');
                            }
                        })
                    } else {
                        // console.log('not selected');
                    }
                }
            })
            let docTitle = data.data.company + '_' + data.data.date;
            setDoc(doc(db, 'NewsLibrary', docTitle), data);
        });
        setCompletedDraggedItems([]);
        cleanNewsLibrary();
    }
    useEffect(() => {
        getData(completedDraggedItems);
    }, [completedDraggedItems]);

    useEffect(() => {
        getSavedDraft();
        getSponsor();
    }, []);

    return (
        <>
            <NewsletterSendStatus
                totalSubscribers={totalSubscribers}
                sentCount={sentCount}
                isActive={isActive}
            />
            <Grid container spacing={1} style={{ display: 'block', marginRight: 0 }}>
                <Grid container>
                    <Grid item xs style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                    }}>
                        <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
                            <Grid item>
                                <Button variant="outlined" onClick={saveDraft}><SaveIcon />&nbsp;حفظ </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    id="demo-customized-button"
                                    aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClickMenu}
                                    endIcon={<KeyboardArrowDownIcon />}
                                ><EmailIcon fontSize='small' />
                                    &nbsp;إرسال
                                </Button>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorElMenu}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={() => { handleCloseMenu(); sendEmail() }} disableRipple>
                                        <SendIcon />
                                        أرسل الآن
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleCloseMenu(); sendTestEmailPopUp() }} disableRipple>
                                        <SendOutlinedIcon />
                                        إرسال بريد تجريبي
                                    </MenuItem>
                                </StyledMenu>
                                <Dialog open={openTestEmail} onClose={handleCloseTestEmail}>
                                    <DialogTitle>إرسال نشرة تجريبية</DialogTitle>
                                    <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={1} sm={1} md={12}>
                                                <Typography variant="h5">الرجاء إدخال البريد الإلكتروني التجريبي</Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={12}>
                                                <TextField
                                                    value={testEmail}
                                                    onChange={({ target: { value } }) => setTestEmail(value)}
                                                    fullWidth
                                                    label="عنوان البريد التجريبي"
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={12}>
                                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={handleCloseTestEmail}> تراجع </Button>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <Button variant="contained" className={classes.submitBtn} size="small" onClick={() => { sendTestEmail(); handleCloseTestEmail(); }}> تأكيد </Button>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </Dialog>
                                {/* <Button size='small' variant="outlined" onClick={sendTestEmail}><SendIcon />إرسال بريد تجريبي إلى </Button> */}
                            </Grid>
                            {/* <Grid item>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="email"
                                    id="outlined-search"
                                    label="البريد التجريبي"
                                    onChange={e => setTestEmail(e.target.value)}
                                />
                            </Grid>
                            
                            <Grid item>
                                <Button size='small' variant="contained" onClick={sendEmail}>أرسل الآن <SendIcon /></Button>
                            </Grid> */}

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mb={2}>
                    <Grid item xs={12}>
                        <TextField
                            value={subject}
                            onChange={({ target: { value } }) => setSubject(value)}
                            fullWidth
                            label="عنوان البريد"
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <IntroCard logo={logo} defaultValue={intro} onChange={setIntro} />
                    </Grid>
                </Grid>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item xs={12} style={{
                            borderRadius: '20px',
                            // boxShadow: '1px 1px 5px #e0e0e0',
                            padding: '35px',
                            background: '#fff'
                        }}>
                            <Typography variant="h6" style={{ marginBottom: '20px', fontWeight: 600 }}>مكتبة الأخبار</Typography>
                            <Droppable droppableId="Items">
                                {(provided, snapshot) => (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}
                                        className={`Items ${snapshot.isDraggingOver ? "dragactive" : ""}`}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >

                                        {items.map((item, index) =>
                                            <Draggable
                                            key={item.id}
                                            draggableId={item.id.toString()}
                                            className={classes.draggable}
                                            index={index}>
                                                {(provided) => (

                                                    <Grid item xs={3}
                                                        key={index}
                                                    // index={index}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>

                                                        <Paper elevation={3} style={{ padding: 20, background: '#f7f7f7', width: '200px', borderRadius: '20px', boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)' }} key={item.id}>
                                                            <Typography variant="p" display="block" style={{ fontWeight: 600, lineHeight: 1.5, fontSize: '14px' }}>
                                                                الفئة: {item.category}
                                                            </Typography>
                                                            <Typography variant="p" display="block" style={{ fontWeight: 600, lineHeight: 1.5, fontSize: '14px' }}>
                                                                التاريخ: {item.date}
                                                            </Typography>
                                                            <Typography variant="p" display="block" style={{ fontWeight: 600, lineHeight: 1.5, fontSize: '14px' }}>
                                                                العنوان: {item.title}
                                                            </Typography>
                                                        </Paper>
                                                        {provided.placeholder}
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <Grid item xs={6} style={{
                            minHeight: '400px',
                            // boxShadow: '1px 1px 5px #e0e0e0',
                            borderRadius: '20px',
                            marginTop: '50px',
                            background: '#fff',
                            // border: '1px solid #e6e6e6',
                            padding: '20px',
                            marginRight: '15px'
                        }}>
                            <Droppable droppableId="TodosRemove">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={`Items  ${snapshot.isDraggingOver ? "dragcomplete" : "remove"
                                            }`}
                                    >
                                        {completedDraggedItems?.map((todo, index) => (
                                            <Draggable
                                                key={todo.id.toString()}
                                                draggableId={"draggable-" + todo.id.toString()}
                                                index={index}>
                                                {(provided) => (

                                                    <div
                                                        key={index}
                                                        index={index}
                                                        style={{ width: '220px' }}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>

                                                        <Paper elevation={3} style={{ padding: 20, marginBottom: 20, backgroundColor: '#f7f7f7', boxShadow: '0 3px 6px rgb(0 0 0 / 16%)' }} key={todo.id}>
                                                            <Typography variant="p" display="block" style={{ fontWeight: 600, lineHeight: 2, fontSize: '14px' }}>
                                                                الفئة: {todo.category}
                                                            </Typography>
                                                            <Typography variant="p" display="block" style={{ fontWeight: 600, lineHeight: 2, fontSize: '14px' }}>
                                                                التاريخ: {todo.date}
                                                            </Typography>
                                                            <Typography variant="p" display="block" style={{ fontWeight: 600, lineHeight: 2, fontSize: '14px' }}>
                                                                العنوان: {todo.title}
                                                            </Typography>
                                                        </Paper>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}



                                    </div>
                                )}
                            </Droppable>
                        </Grid>
                        <Grid item xs={6} style={{
                            minHeight: '400px',
                            borderRadius: '20px',
                            marginTop: '50px',
                            marginRight: 10,
                            paddingRight: 0,
                            paddingTop: 0
                        }}>
                            <Preview
                                ref={previewCompRef}
                                data={completedDraggedItems}
                                intro={intro}
                                compData={data}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            size='small'
                            // className={classes.preview}
                            variant="contained"
                            endIcon={<SearchIcon />}
                            onClick={() => previewCompRef.current.showPreview()}
                        >معاينة</Button>
                    </Grid>
                </DragDropContext>
            </Grid>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
}
const mapStateToProps = (state) => {
    return state
}
const dispatchCompany = (dispatch) => {
    return {
        updateQuota: (totalNumberOfEmailsSent) => { dispatch({ type: 'UPDATE_QUOTA', emailsSent: totalNumberOfEmailsSent }) }
    }
}

export default connect(mapStateToProps, dispatchCompany)(CreateNewsletter);
