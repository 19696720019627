import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Snackbar,
} from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';

import { makeStyles } from "tss-react/mui";
import { isEmpty } from 'lodash';



const AddEditSubscriber = ({ buttonName, name, email, id, index, handleAddSubscriber }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [subName, setSubName] = useState(name);
    const [subEmail, setSubEmail] = useState(email);
    // const [visible, setVisible] = useState(false);
    const db = getFirestore();

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px'
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },


        })
    );

    const { classes } = useStyles();


    return (
        <>
            <Grid container spacing={2} mb={2} columns={{ xs: 1, sm: 1, md: 12 }} style={{ marginRight: 0, width: '100%' }}>
                <Paper elevation={0} className={classes.textPaper}>
                    <Grid item className={classes.addCatForm}>
                        <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={2}>
                                <Typography variant="body1">
                                    الإسم
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={10}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-name"
                                    index={index}
                                    value={subName}
                                    onChange={(e) => setSubName(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.addCatForm} mt={2}>
                        <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={2}>
                                <Typography variant="body1">
                                    البريد اللإلكتروني
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={10}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-name"
                                    index={index}
                                    value={subEmail}
                                    onChange={(e) => setSubEmail(e.target.value)}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item  xs={1} sm={1} md={12} mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            size='small'
                            variant="contained"
                            onClick={e => handleAddSubscriber(subName, subEmail, id, index)} >
                            {buttonName}
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </>

    );
};

export default AddEditSubscriber;
