import React, { useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Button,
    Typography,
    Autocomplete,
    TextField,
    Box
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { makeStyles } from "tss-react/mui";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getFirestore, getDocs, collection, query, where, Timestamp } from 'firebase/firestore';
import { startOfDay, endOfDay, addDays, format, subDays, differenceInDays } from 'date-fns';
import { getAuth } from "firebase/auth";
import { connect } from 'react-redux';
import moment from 'moment/moment';
import DatePicker from "react-datepicker";
import { confirm } from "react-confirm-box";
// import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts'
import AWS from 'aws-sdk';
import { enUS } from 'date-fns/locale';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

const Statistics = (props) => {
    const { data } = props;
    const history = useHistory();
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const isFreePackage = props.data?.settings?.package?.type === 'free';
    // Check if the current route is '/statistics'
    // const isStatisticsPage = rest.path === '/statistics';

    //aws config for cloudwatch access
    AWS.config.update({
        accessKeyId: data?.settings?.senderSettings?.aws?.cw?.AccessKey?.AccessKeyId,
        secretAccessKey: data?.settings?.senderSettings?.aws?.cw?.AccessKey?.SecretAccessKey,
        region: 'us-east-1' // replace with your region
    });
    // AWS.config.update({
    //     accessKeyId: 'AKIAWX5D3OVVK22ZJL4G',
    //     secretAccessKey: 'ceeaLiqLSUYU6tPa4FY1LU/vPDY1D8Y9LG5NcoGY',
    //     region: 'us-east-1' // replace with your region
    // });
    const ses = new AWS.CloudWatch();
    // const client = new AWS.SES({ region: "us-east-1" });
    let from = new Date();
    from.setDate(from.getDate() - 7);

    const [subscribers, setSubscribers] = useState(0);
    const [totalSubscribers, setTotalSubscribers] = useState(0);
    const [fromDate, setFromDate] = useState(from);
    const [toDate, setToDate] = useState(new Date());
    const [newSubscribers, setNewSubscribers] = useState(0);
    const [newUnSubscribers, setNewUnSubscribers] = useState(0);
    const [subscribersPercentage, setSubscribersPercentage] = useState(0);
    const [unSubscribersPercentage, setUnSubscribersPercentage] = useState(0);
    const [todayUnsubscribed, setTodayUnsubscribed] = useState(0);
    const [todayClicked, setTodayClicked] = useState(0);
    const [todayDelivered, setTodayDelivered] = useState(0);
    const [todayOpened, setTodayOpened] = useState(0);
    const [todayEmailTotal, setTodayEmailTotal] = useState(0);
    const [daysForPercentage, setDaysForPercentage] = useState(7);
    const { id, company } = props;

    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    const [series, setSeries] = useState([
        {
            name: 'النشرات المرسلة',
            data: []
        },
        {
            name: 'النشرات المستلمة',
            data: []
        },
        {
            name: 'النشرات التي تم فتحها',
            data: []
        },
        {
            name: 'النقرات',
            data: []
        },
    ])
    // const [graphCategories, setGraphCategories] = useState([]);
    const [graphOptions, setGraphOptions] = useState({
        series: series,
        options: {
            chart: {
                height: 150,
                type: 'area',
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                itemMargin: {
                    horizontal: 15,
                    vertical: 30
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: []
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },

        },
    });
    const graphSelectDates = [
        {
            id: 'week',
            name: 'آخر أسبوع'

        },
        {
            id: 'month',
            name: 'آخر شهر'
        },
        {
            id: 'semester',
            name: '3 أشهر'

        },
        {
            id: 'semiyear',
            name: '6 أشهر'

        },
        {
            id: 'year',
            name: 'سنة'

        },
        {
            id: 'custom',
            name: 'تاريخ مخصص'
        }
    ];
    const [statOptionSelect, setStatOptionSelect] = useState(graphSelectDates[0].name);


    const CurrentDate = new Date();
    const defaultSelected = {
        from: subDays(CurrentDate, 4),
        to: CurrentDate
    };

    const [range, setRange] = useState(defaultSelected);
    let footer = <p>Please pick the first day.</p>;
    if (range?.from) {
        if (!range.to) {
            footer = <p>{format(range.from, 'PPP')}</p>;
        } else if (range.to) {
            footer = (
                <p>
                    {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
                </p>
            );
        }
    }


    const useStyles = makeStyles()(
        () => ({
            graphFilter: {
                '& .muirtl-j24kgu-MuiInputBase-root-MuiInput-root:before': {
                    borderBottom: 0
                }
            },
            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#f27021'
            }

        })
    );

    const { classes } = useStyles();
    //DateRange box to statistics
    const [openDateRange, setOpenDateRange] = useState(false);
    const handleClickDateRange = () => {
        setOpenDateRange(true);
    };
    const handleCloseDateRange = () => {
        setOpenDateRange(false);
    };
    //DateRange box to statistics
    const convert_to_iso_Date = (date, type) => {
        date.setHours(0, 0, 0, 0);
        if (type == 'to') {
            date.setHours(23, 59, 59, 0);
        }
        return date.toISOString();
    }

    const subtractDaysFromDate = (date, numOfDays) => {
        const dateCopy = new Date(date.getTime());
        dateCopy.setDate(dateCopy.getDate() - numOfDays);
        // console.log(dateCopy);
        return dateCopy;
    }

    const todayBtn = () => {
        fetchData(fromDate, fromDate);
        setTotalSubscribers(subscribers);
    }
    const yesterdayBtn = () => {
        let from = subtractDaysFromDate(fromDate, 1);
        let to = fromDate;
        setTotalSubscribers(subscribers * 2);
        fetchData(from, to);
    }
    const lastWeekBtn = () => {
        let from = subtractDaysFromDate(fromDate, 7);
        let to = fromDate;
        setTotalSubscribers(subscribers * 7);
        fetchData(from, to);
    }
    const lastMonthBtn = () => {
        let from = subtractDaysFromDate(fromDate, 30);
        let to = fromDate;
        setTotalSubscribers(subscribers * 30);
        fetchData(from, to);
    }
    const customDateBtn = () => {

        let subtDates = (toDate.getDate() - fromDate.getDate()) + 1;
        console.log(subtDates);
        setTotalSubscribers(subscribers * subtDates);
        fetchData(fromDate, toDate);
    }
    const getNumOfSubscribers = async () => {
        const subRef = collection(db, "Subscribers");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        setSubscribers(data.size);
    }
    function getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment.utc(startDate); // use utc() to set start date to GMT
        var stopDate = moment.utc(stopDate); console.log(stopDate); // use utc() to set stop date to GMT
        while (currentDate <= stopDate) {
            dateArray.push(currentDate.format('YYYY-MM-DD')); // format date in GMT
            currentDate = currentDate.add(1, 'days');
        }
        const isoStrings = dateArray.map(date => moment.utc(date).toISOString()); // convert dates to ISO strings in GMT
        return isoStrings;
    }
    const fetchData = (from, to) => {
        let diff = getDates(from, to);
        let _from = convert_to_iso_Date(from, 'from');
        let _to = convert_to_iso_Date(to, 'to');
        const paramsSend = {
            Namespace: 'AWS/SES',
            MetricName: 'Send',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsDelivered = {
            Namespace: 'AWS/SES',
            MetricName: 'Delivery',
            StartTime: _from,
            EndTime: _to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsOpened = {
            Namespace: 'AWS/SES',
            MetricName: 'Open',
            StartTime: _from,
            EndTime: _to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsClicked = {
            Namespace: 'AWS/SES',
            MetricName: 'Click',
            StartTime: _from,
            EndTime: _to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        ses.getMetricStatistics(paramsSend, (err, sendData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                sendData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestamp asc
                let emailsSent = [];
                let emailsDelivered = [];
                let emailsOpened = [];
                let emailsClicked = [];
                let added = false;
                if(sendData.Datapoints.length > 0){
                    for (let i = 0; i < diff.length; i++) {
                        let date = new Date(diff[i]);
                        let offset = date.getTimezoneOffset();
                        let adjustedOffset = sendData.Datapoints[0].Timestamp.getTimezoneOffset();
                        let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                        added = false;
                        for (let y = 0; y < sendData.Datapoints.length; y++) {
                            let _dataDate = ((new Date(sendData.Datapoints[y].Timestamp).getTime()) - (new Date(sendData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                            if (_dataDate === adjustedDate) {
                                emailsSent.push(sendData.Datapoints[y].Sum);
                                added = true;
                            }
                        }
                        if (!added) {
                            emailsSent.push(0);
                        }
                    }
                    ses.getMetricStatistics(paramsDelivered, (err, deliveredData) => {
                        if (err) {
                            console.log(err, err.stack);
                        } else {
                            deliveredData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestap asc
                            for (let i = 0; i < diff.length; i++) {
                                let date = new Date(diff[i]);
                                let offset = date.getTimezoneOffset();
                                let adjustedOffset = deliveredData.Datapoints[0].Timestamp.getTimezoneOffset();
                                let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                                added = false;
                                for (let y = 0; y < deliveredData.Datapoints.length; y++) {
                                    let _dataDate = ((new Date(deliveredData.Datapoints[y].Timestamp).getTime()) - (new Date(deliveredData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                                    if (_dataDate === adjustedDate) {
                                        emailsDelivered.push(deliveredData.Datapoints[y].Sum);
                                        added = true;
                                    }
                                }
                                if (!added) {
                                    emailsDelivered.push(0);
                                }
                            }
                            ses.getMetricStatistics(paramsOpened, (err, openedData) => {
                                if (err) {
                                    console.log(err, err.stack);
                                } else {
                                    openedData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestap asc
                                    for (let i = 0; i < diff.length; i++) {
                                        let date = new Date(diff[i]);
                                        let offset = date.getTimezoneOffset();
                                        let adjustedOffset = openedData.Datapoints[0].Timestamp.getTimezoneOffset();
                                        let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                                        added = false;
                                        for (let y = 0; y < openedData.Datapoints.length; y++) {
                                            let _dataDate = ((new Date(openedData.Datapoints[y].Timestamp).getTime()) - (new Date(openedData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                                            if (_dataDate === adjustedDate) {
                                                emailsOpened.push(openedData.Datapoints[y].Sum);
                                                added = true;
                                            }
                                        }
                                        if (!added) {
                                            emailsOpened.push(0);
                                        }
                                    }
                                    ses.getMetricStatistics(paramsClicked, (err, clickedData) => {
                                        if (err) {
                                            console.log(err, err.stack);
                                        } else {
                                            clickedData.Datapoints.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)); //sort timestap asc
                                            for (let i = 0; i < diff.length; i++) {
                                                let date = new Date(diff[i]);
                                                let offset = date.getTimezoneOffset();
                                                if (clickedData.Datapoints.length > 0) {
                                                    let adjustedOffset = clickedData.Datapoints[0].Timestamp.getTimezoneOffset();
                                                    let adjustedDate = (date.getTime() - (offset - adjustedOffset) * 60 * 1000);
                                                    added = false;
                                                    for (let y = 0; y < clickedData.Datapoints.length; y++) {
                                                        let _dataDate = ((new Date(clickedData.Datapoints[y].Timestamp).getTime()) - (new Date(clickedData.Datapoints[y].Timestamp).getTimezoneOffset() * 60000))
                                                        if (_dataDate === adjustedDate) {
                                                            emailsClicked.push(clickedData.Datapoints[y].Sum);
                                                            added = true;
                                                        }
                                                    }
                                                    if (!added) {
                                                        emailsClicked.push(0);
                                                    }
                                                } else {
                                                    emailsClicked.push(0);
                                                }
                                            }
                                            //update usestate graphOptions
                                            setGraphOptions({
                                                ...graphOptions,
                                                series: [
                                                    {
                                                        name: 'النشرات المرسلة',
                                                        data: emailsSent
                                                    },
                                                    {
                                                        name: 'النشرات المستلمة',
                                                        data: emailsDelivered
                                                    },
                                                    {
                                                        name: 'النشرات التي تم فتحها',
                                                        data: emailsOpened
                                                    },
                                                    {
                                                        name: 'النقرات',
                                                        data: emailsClicked
                                                    },
                                                ],
                                                options: {
                                                    ...graphOptions.options,
                                                    xaxis: {
                                                        ...graphOptions.options.xaxis,
                                                        categories: diff
                                                    }
                                                }
                                            });
                                            //update usestate graphOptions
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
                
            }
        });
    }
    const getFilteredStatistics = (value) => {
        console.log(value);
        if (value.id == '') {
            return;
        }
        let toDate = new Date();
        let fromDate;
        setStatOptionSelect(value.name);
        switch (value.id) {
            case 'week':
                fromDate = subtractDaysFromDate(toDate, 7);
                setDaysForPercentage(7);
                fetchData(fromDate, toDate);
                break;
            case 'month':
                fromDate = subtractDaysFromDate(toDate, 30);
                setDaysForPercentage(30);
                fetchData(fromDate, toDate);
                break;
            case 'semester':
                fromDate = subtractDaysFromDate(toDate, 90); // 3 months
                setDaysForPercentage(90);
                fetchData(fromDate, toDate);
                break;
            case 'semiyear':
                fromDate = subtractDaysFromDate(toDate, 182); //6 months
                setDaysForPercentage(182);
                fetchData(fromDate, toDate);
                break;
            case 'year':
                fromDate = subtractDaysFromDate(toDate, 365); //1 year
                setDaysForPercentage(365);
                fetchData(fromDate, toDate);
                break;
            case 'custom':
                setOpenDateRange(true);
                break;
        }

    }
    const todayRep = () => {
        let from = convert_to_iso_Date(new Date(), 'from');
        let to = convert_to_iso_Date(new Date(), 'to');
        const paramsSend = {
            Namespace: 'AWS/SES',
            MetricName: 'Send',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsDelivered = {
            Namespace: 'AWS/SES',
            MetricName: 'Delivery',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsOpened = {
            Namespace: 'AWS/SES',
            MetricName: 'Open',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        const paramsClicked = {
            Namespace: 'AWS/SES',
            MetricName: 'Click',
            StartTime: from,
            EndTime: to,
            Period: 86400, //24 hrs
            Statistics: ['Sum'],
        };
        let emailsSent = 0;
        let emailsDelivered = 0;
        let emailsOpened = 0;
        let emailsClicked = 0;
        let dateCat = 0;
        ses.getMetricStatistics(paramsSend, (err, sendData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (sendData?.Datapoints[0]?.Sum) {
                    emailsSent = sendData.Datapoints[0].Sum;
                    setTodayEmailTotal(emailsSent);
                } else {
                    setTodayEmailTotal(0);
                }

            }
        });

        ses.getMetricStatistics(paramsDelivered, (err, deliveredData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (deliveredData?.Datapoints[0]?.Sum) {
                    emailsDelivered = deliveredData.Datapoints[0].Sum;
                    setTodayDelivered(emailsDelivered);
                } else {
                    setTodayDelivered(0);
                }

            }
        })
        ses.getMetricStatistics(paramsOpened, (err, openedData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (openedData?.Datapoints[0]?.Sum) {
                    emailsOpened = openedData.Datapoints[0].Sum;
                    setTodayOpened(emailsOpened);
                } else {
                    setTodayOpened(0);
                }

            }
        })
        ses.getMetricStatistics(paramsClicked, (err, clickedData) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                if (clickedData?.Datapoints[0]?.Sum) {
                    emailsClicked = clickedData?.Datapoints[0]?.Sum;
                    setTodayClicked(emailsClicked);
                } else {
                    setTodayClicked(0);
                }
            }
        })


    }

    const newSubscribersFn = async () => {
        // Get the start and end timestamps for the specific date
        const today = new Date();
        const startTimestamp = Timestamp.fromDate(startOfDay(today));
        const endTimestamp = Timestamp.fromDate(endOfDay(today));

        let newUsers = query(collection(db, 'Subscribers'), where("company", "==", company), where('createdAt', '>=', startTimestamp), where('createdAt', '<=', endTimestamp));
        let allUsers = query(collection(db, 'Subscribers'), where("company", "==", company));
        let queryNewUsers = await getDocs(newUsers);
        let queryAllUsers = await getDocs(allUsers);
        console.log(queryNewUsers.docs.length);
        setNewSubscribers(queryNewUsers.docs.length);
        let percentage = 0;
        if (queryNewUsers.docs.length == 0 || queryAllUsers.docs.length == 0) {
            percentage = 0;
        } else {
            percentage = (queryNewUsers.docs.length / queryAllUsers.docs.length) * 100;
        }
        setSubscribersPercentage(percentage.toFixed(2));
    }
    const newUnSubscribersFn = async () => {
        const today = new Date();
        const startTimestamp = Timestamp.fromDate(startOfDay(today));
        const endTimestamp = Timestamp.fromDate(endOfDay(today));

        let newUnsubscribers = query(collection(db, 'Unsubscribers'), where("company", "==", company), where('createdAt', '>=', startTimestamp), where('createdAt', '<=', endTimestamp));
        let allUsers = query(collection(db, 'Subscribers'), where("company", "==", company));
        let queryNewUsers = await getDocs(newUnsubscribers);
        let queryAllUsers = await getDocs(allUsers);
        setNewUnSubscribers(queryNewUsers.docs.length);
        let percentage = 0;
        if (queryNewUsers.docs.length == 0 || queryAllUsers.docs.length == 0) {
            percentage = 0;
        } else {
            percentage = (queryNewUsers.docs.length / queryAllUsers.docs.length) * 100;
        }
        setUnSubscribersPercentage(percentage.toFixed(2));
    }
    const handleDateRange = () => {
        var timeDiff = range.to.getTime() - range.from.getTime();

        // Convert the time difference to days
        var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        console.log(daysDiff);
        console.log(differenceInDays(range.to, range.from));
        setDaysForPercentage(differenceInDays(range.to, range.from));
        fetchData(range.from, range.to);
    }
    useEffect(() => { console.log(range) }, [range])
    useEffect(() => {
        getNumOfSubscribers();
        fetchData(fromDate, toDate);
        todayRep();
        newSubscribersFn();
        newUnSubscribersFn();
    }, []);
    useEffect(() => {
        if (isFreePackage) {
            const result = confirm("لا يمكنك الدخول إلى قسم الإحصائيات، هل تريد تطوير الباقة؟", options);
            if (result) {
                history.goBack();
                return null;
            }

        }
    }, [])

    return (
        <>

            <Dialog open={openDateRange} onClose={handleCloseDateRange}>
                <DialogTitle>إختر تاريخ مخصص</DialogTitle>
                <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item xs={1} sm={1} md={12}>
                            <DayPicker
                                locale={enUS}
                                dir="rtl"
                                id="test"
                                mode="range"
                                defaultMonth={CurrentDate}
                                selected={range}
                                // footer={footer}
                                onSelect={setRange}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={12}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={handleCloseDateRange}> تراجع </Button>
                                </Grid>
                                <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button variant="contained" className={classes.submitBtn} size="small" onClick={() => { handleDateRange(); handleCloseDateRange(); }}> تأكيد </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
            <Grid container spacing={4} mt={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item xs={1} sm={1} md={5}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={5.5}>
                                <Typography variant="subtitle1" align='center' sx={{ fontWeight: 'bold' }}>المرسلة اليوم</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المرسلة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayEmailTotal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المستلمة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayDelivered}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>تم الفتح</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayOpened}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">النقرات</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayClicked}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">إلغاء الإشتراك</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">البريد المزعج</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={5}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={5.5}>
                                <Typography pl={3} variant="subtitle1" align='center' sx={{ fontWeight: 'bold' }}>المشتركين&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={7.5}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>عدد المشتركين: {subscribers} مشترك</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} mt={4}>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={8}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                            <Grid item xs={1} sm={1} md={8}>
                                                                <Typography variant="body2" color='red' style={{
                                                                    fontSize: '0.75rem',
                                                                    textAlign: 'left',
                                                                    lineHeight: 1.4,
                                                                    paddingTop: '6px'
                                                                }}>{unSubscribersPercentage}%-</Typography>
                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={4}>
                                                                <Typography variant="h6" align='center' style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newUnSubscribers}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Typography style={{ fontSize: '0.80rem', textAlign: 'left', paddingLeft: '15px' }} variant="body2" align='center'>إلغاء الإشتراك</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ArrowDownwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={8}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                            <Grid item xs={1} sm={1} md={8}>
                                                                <Typography variant="body2" color='green' style={{
                                                                    fontSize: '0.75rem',
                                                                    textAlign: 'left',
                                                                    lineHeight: 1.4,
                                                                    paddingTop: '6px'
                                                                }}>{subscribersPercentage}%+</Typography>
                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={4}>
                                                                <Typography variant="h6" align='center' style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newSubscribers}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Typography style={{ fontSize: '0.80rem', textAlign: 'left', paddingLeft: '15px' }} variant="body2" align='center'>مشترك جديد</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ArrowUpwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={4} mt={1} mb={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item md={12}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} mt={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={2.5}>
                                <Autocomplete
                                    className={classes.graphFilter}
                                    disableClearable
                                    size='small'
                                    options={graphSelectDates}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={graphSelectDates[0]}
                                    onChange={(event, value) => getFilteredStatistics(value)}
                                    id="disable-clearable"
                                    renderInput={(params) => (
                                        <TextField {...params} label="" variant="standard" />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box style={{ fontSize: 14 }} {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Chart
                                    options={graphOptions.options}
                                    series={graphOptions.series}
                                    type="area"
                                    height={350}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={4} mb={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item xs={1} sm={1} md={12}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={12}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>مرسلة {statOptionSelect}</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المرسلة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>{((graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0) / (subscribers * daysForPercentage)) * 100).toFixed(2)}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المستلمة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>{((graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>تم الفتح</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">{((graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">النقرات</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{graphOptions.series[3].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">{((graphOptions.series[3].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">إلغاء الإشتراك</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">البريد المزعج</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </>

    );

}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Statistics);
