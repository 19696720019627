import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    TextField,
    Grid,
    Alert,
    Typography,
    Link
} from '@mui/material';
import { getAuth, confirmPasswordReset, checkActionCode, applyActionCode } from 'firebase/auth';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, documentId, setDoc, collection, query, where } from 'firebase/firestore';
import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg.jpg';

const ConfirmResetPasswordAndEmailVerification = () => {
    const history = useHistory();
    const [oobCode, setOobCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isloading, setIsloading] = useState(true);
    const [resetPassword, setResetPassword] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    const auth = getAuth();
    const db = getFirestore();
    const errorMessages = {
        'auth/expired-action-code': 'انتهت صلاحية الرابط',
        'empty-password-field': 'انتهت صلاحية الرابط',
    };

    const onSubmit = () => {
        if (newPassword == '') {
            console.log('fill password field');
            // popup error please fill email address
            return;
        }
        if (newPassword.length < 6) {
            console.log('password is too short');
            return;
        }
        confirmPasswordReset(auth, oobCode, newPassword)
            .then((signInMethods) => {
                history.push('/login');
            })
    }
    const goToLogin = () => {
        history.push('/login');
    }
    useEffect(() => {
        // Extract the oobCode parameter from the URL
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        checkActionCode(auth, params.oobCode)
            .then((data) => {
                setOobCode(params.oobCode);
                console.log(params.oobCode);
                if (params.mode == "resetPassword") {
                    setResetPassword(true);
                    setVerifyEmail(false);
                    console.log('reset');
                } else {
                    setResetPassword(false);
                    setVerifyEmail(true);
                    console.log('verify');
                    applyActionCode(auth, params.oobCode)
                        .then(() => {
                            setEmailVerified(true);
                            console.log('email verified')
                        })
                }
                setIsloading(false);
            })
            .catch((err) => {
                setErrorMessage(errorMessages[err.code]);
                setIsloading(false);
            })
    }, []);
    return (
        <>
            {resetPassword && <>
                <section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
                    data-image-src="../Public/assets/img/mujaz/mujaz-bg.jpg"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                    <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <h1 className="display-1 mb-3">إعادة تعيين كلمة المرور</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                                <div className="card">
                                    <div className="card-body p-11 text-center">
                                        {!isloading && <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>
                                            {Boolean(errorMessage) && (
                                                <Alert severity="error" color="error" style={{ width: '100%' }}>
                                                    <Typography>{errorMessage}</Typography>
                                                </Alert>
                                            )}
                                            {Boolean(errorMessage) &&
                                                <Typography mt={2} sx={{ width: '100%' }} variant='body2'>
                                                    <Link href="/forget_password">المحاولة من جديد</Link>
                                                </Typography>}
                                            {!Boolean(errorMessage) && <Grid item xs={1} sm={1} md={1}>
                                                <Typography variant='body2' className="lead mb-6 text-end">الرجاء إدخال كلمة المرور الخاصة بك</Typography>
                                            </Grid>}
                                            {!Boolean(errorMessage) && <Grid item xs={1} sm={1} md={1}>
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    onChange={(event) => setNewPassword(event.target.value)}
                                                    value={newPassword}
                                                    label="كلمة المرور الجديدة"
                                                    variant="outlined"
                                                    id="loginEmail"
                                                />
                                            </Grid>}
                                            {!Boolean(errorMessage) && <Grid item xs={1} sm={1} md={1}>
                                                <Button
                                                    onClick={() => onSubmit()}
                                                    variant="contained"
                                                    className="btn btn-primary btn-login w-100 mb-2"> تعيين كلمة المرور</Button>
                                            </Grid>}
                                        </Grid>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>}
            {(verifyEmail && emailVerified) && <>
                <section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
                    data-image-src="../Public/assets/img/mujaz/mujaz-bg.jpg"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                    <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <h1 className="display-1 mb-3">التحقق من البريد الإلكتروني</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                                <div className="card">
                                    <div className="card-body p-11 text-center">
                                        <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>

                                            <Alert severity="success" color="success" style={{ width: '100%' }}>
                                                <Typography>تم التحقق من البريد الإلكتروني بنجاح</Typography>
                                            </Alert>

                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Button
                                                style={{ marginTop: '25px' }}
                                                fullWidth
                                                onClick={() => goToLogin()}
                                                variant="contained"
                                                className="btn btn-primary btn-login w-100 mb-2"> تسجيل الدخول</Button>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>}
        </>
    );
};


export default ConfirmResetPasswordAndEmailVerification;
