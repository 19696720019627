import React, { useState, useEffect, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { generateHtmlView, generateWrappedEmail, generateKey, generateStocksCard, generateCurrencyCard, generateWeatherCard, generateImageCard, generatePDFCard, generateNewsCard, generateIntroCard, generateFooter } from '../utils';
import { FacebookEmbed, InstagramEmbed, TwitterEmbed, YouTubeEmbed } from 'react-social-media-embed';

import html2canvas from "html2canvas";
// import { PDFReader } from 'reactjs-pdf-reader';
const Preview = forwardRef((props, ref) => {

    const [items, setItems] = useState([]);
    const [HTML, setHTML] = useState('');
    const { data, intro, compData } = props;
    let primaryColor = compData?.settings?.view?.primaryColor?.hex;
    let senderEmail = compData?.settings?.senderSettings?.email;
    let footer = compData?.settings?.footer;
    let website = compData?.personalProfile?.companyData?.website;
    let compLogo = compData?.settings?.view?.logo;

    const CATEGORY = {
        STOCKS: 'البورصة',
        CURRENCY: 'أسعار المال',
        IMAGE: 'صورة',
        PDF: 'بي دي أف',
        WEATHER: 'توقعات الطقس',
        TWITTER: 'تويتر',
        FACEBOOK: 'فايسبوك',
        INSTAGRAM: 'إنستاغرام',
        YOUTUBE: 'يوتيوب'
    }
    useImperativeHandle(ref, () => ({
        showPreview() {
            updateItems();
            // generateView();
        },
    }))

    const generateView = () => {

        // let finalHTML = generateHtmlView(items, intro);
        // setHTML(finalHTML);
    }

    const screenshot = () => {
        html2canvas(document.getElementById('instagram-embed-0')).then((canvas) => {
            console.log('yes');
            const image = canvas.toDataURL("image/png", 1.0);
            console.log(image);
        });
    }

    const updateItems = () => {

        if (data.length > 0) {
            setItems(prev => {
                return data.map((d, index) => {
                    return d;
                });
            });
        }
    }
    useEffect(() => {
        console.log(compData);
        if (items.length > 0) {
            generateView();
        }
    }, [items])

    return (
        <div id="newletter">
            <div dangerouslySetInnerHTML={{ __html: generateIntroCard(compLogo, intro, website) }} />
            {items.map((item, index) => {
                let totalItems;
                switch (item.category) {
                    case CATEGORY.STOCKS:
                        totalItems = generateStocksCard(item.items, item.body, primaryColor);
                        return <div dangerouslySetInnerHTML={{ __html: totalItems }} key={index} />
                        break;
                    case CATEGORY.CURRENCY:
                        totalItems = generateCurrencyCard(item.items, item.date, primaryColor);
                        return <div dangerouslySetInnerHTML={{ __html: totalItems }} key={index} />
                        break;
                    case CATEGORY.WEATHER:
                        totalItems = generateWeatherCard(item.items, item.body, item.date, primaryColor);
                        return <div dangerouslySetInnerHTML={{ __html: totalItems }} key={index} />
                        break;
                    case CATEGORY.IMAGE:
                        totalItems = generateImageCard(item.section, item.title, item.image, item.imageURL, item.credits, item.alt, primaryColor);
                        return <div dangerouslySetInnerHTML={{ __html: totalItems }} key={index} />
                        break;
                    case CATEGORY.PDF:
                        console.log('pdf');
                        // totalItems = generatePDFCard(item.section, item.title, item.pdf, item.credits, item.alt);
                        // return <PDFReader url={item.pdf} />
                        console.log(totalItems);
                        return <div dangerouslySetInnerHTML={{ __html: totalItems }} key={index} />
                        break;
                    case CATEGORY.TWITTER:
                        totalItems = <TwitterEmbed url={item.tweet.url} key={index} />;
                        return totalItems;
                        break;
                    case CATEGORY.FACEBOOK:
                        totalItems = <FacebookEmbed url={item.post.url} key={index} />;
                        return totalItems;
                        break;
                    case CATEGORY.INSTAGRAM:
                        totalItems = <InstagramEmbed url={item.post.url} key={index} />;

                        // item = <div ref={imageRef} key={index} class="socialMedia"><InstagramEmbed url={item.post.url} key={index} /></div>;
                        // totalItems = captureScreenShot();
                        // console.log(item);

                        return totalItems;
                        break;
                    case CATEGORY.YOUTUBE: ;
                        totalItems = <YouTubeEmbed url={item.video.url} key={index} />;
                        return totalItems;
                        break;
                    default:
                        totalItems = generateNewsCard(item.category, item.title, item.image, item.imageURL, item.credits, item.alt, item.body, primaryColor, false);
                        // console.log(totalItems);
                        return <div dangerouslySetInnerHTML={{ __html: totalItems }} key={index} />
                        break;
                }
            })}
            <div dangerouslySetInnerHTML={{ __html: generateFooter(primaryColor, website, footer, senderEmail) }} />
        </div>

    )
});


export default Preview;