import React from 'react';
import { Container } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SideBarMenu from '../components/SideBarMenu';
import { Grid } from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { ProSidebarProvider } from 'react-pro-sidebar';
const DashboardContainer = ({ children }) => {

	const useStyles = makeStyles()(
		() => ({
			main: {
				width: '100%',
				'@media only screen and (min-width: 1200px)': {
					width: '100%',
				},
			},
			navContainer: {
				maxWidth: '20%'
			},
			container: {
				boxSizing: 'border-box',
				display: 'flex',
				flexWrap: 'nowrap',
				width: '100%',
				flexDirection: 'row',
				marginTop: 0,
				justifyContent: 'space-between',
				alignItems: 'flex-start',
			},
			welomeText: {
				color: '#ff6633',
				fontWeight: 700,
			},
			paper: {
				display: 'flex',
				flexWrap: 'nowrap',
				alignItems: 'center',
				flexDirection: 'column',
				marginTop: 210,
			},
			confirmBtnConfirm: {
				background: 'blue',
			},

		})
	);

	const { classes } = useStyles();
	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh'
		  }}>
			<Container maxWidth="100%" style={{ paddingRight: 0, flex: 1 }}>
			  <ProSidebarProvider>
				<Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
				  <Grid item xs={2}>
					<SideBarMenu style={{ minHeight: 'calc(105vh + 100px)' }} />
				  </Grid>
				  <Grid item xs={10}>
					<Grid container columns={{ xs: 1, sm: 1, md: 12 }} style={{ justifyContent: 'space-around', minHeight: 'calc(105vh + 100px)', marginBottom: '50px' }}>
					  <Grid item xs={1} sm={1} md={10}>
						<Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
						  <Grid item md={12} mt={7}>
							<Navbar />
						  </Grid>
						  <Grid item md={12} mt={6} style={{ minHeight: 'calc(105vh - 170px)' }}>
							{children}
						  </Grid>
						</Grid>
					  </Grid>
					</Grid>
				  </Grid>
				</Grid>
			  </ProSidebarProvider>
			</Container>
		  </div>
		  

	);
};

export default DashboardContainer;
