import React, { useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { useSigninCheck } from 'reactfire';
import { getAuth, signOut } from 'firebase/auth';
import { connect } from 'react-redux';

const HeaderNavBar = (props) => {
    const history = useHistory();
    const signInCheckResult = useSigninCheck();
    const [signedIn, setSignedIn] = useState(signInCheckResult.data.signedIn);
    const auth = getAuth();
    const gotologin = () => {
        history.push('/login');
    }
    const gotosignup = () => {
        history.push('/signup');
    }
    const logoutUser = () => {
		props.restState();
		signOut(auth);
	}
    return (
        <nav className="navbar navbar-expand-lg classic center-nav transparent navbar-light">
            <div className="container flex-lg-row flex-nowrap align-items-center">
                <div className="navbar-brand">
                    <a href="/">
                        <img src="./img/icon/logo-01.png" width="200" />
                    </a>
                </div>
                <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                    <div className="offcanvas-body mx-xl-auto d-flex flex-column h-100">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href='#home'>الرئيسية</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href='#aboutus'>من نحن</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href='#specifics'>مميزات</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href='#prices'>الأسعار</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href='#contactus'>التواصل معنا</AnchorLink>
                            </li>
                        </ul>
                        <div className="d-lg-none mt-auto pt-6 pb-6 order-4">
                            <a href="mailto:first.last@email.com" className="link-inverse">info@email.com</a>
                            <br /> 00 (123) 456 78 90 <br />
                            <nav className="nav social social-white mt-4">
                                <a href="#"><i className="uil uil-twitter"></i></a>
                                <a href="#"><i className="uil uil-facebook-f"></i></a>
                                <a href="#"><i className="uil uil-dribbble"></i></a>
                                <a href="#"><i className="uil uil-instagram"></i></a>
                                <a href="#"><i className="uil uil-youtube"></i></a>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="navbar-other ms-lg-4">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <li className="nav-item"><a className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-info"><i className="uil uil-info-circle"></i></a></li>
                        <li className="nav-item me-lg-3 d-none d-md-block">
                            {!signedIn ?
                                <>
                                    <Button size='small' variant='contained' onClick={gotologin}>تسجيل الدخول</Button>&nbsp;&nbsp;
                                    <Button size='small' variant='outlined' onClick={gotosignup}>تسجيل حساب جديد</Button>
                                </> :
                                <Button size='small' variant='contained' onClick={() => logoutUser()} >تسجيل الخروج</Button>}
                        </li>
                        <li className="nav-item  d-lg-none">
                            <button className="hamburger offcanvas-nav-btn"><span></span></button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
const dispatchUser = (dispatch) => {
	return {
		restState: () => { dispatch({ type: 'RESET_STATE' }) }
	}
}
export default connect(null, dispatchUser)(HeaderNavBar);
