import React, { useState } from 'react';
import {
    Avatar,
    Button,
    TextField,
    Link,
    Paper,
    Box,
    Grid,
    Typography,
    Stack,
    Container,
    Alert,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut, sendSignInLinkToEmail } from 'firebase/auth';
import firebase, { initializeApp } from 'firebase/app';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, documentId, setDoc, collection, query, where, limit } from 'firebase/firestore';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';
import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg.jpg';

const errorMessages = {
    'missing-fields': 'الرجاء إدخال جميع المعلومات المطلوبة',
    'email-taken': 'البريد الإلكتروني مسجل مسبقاً',
    'email-wrong-format': 'البريد الإلكتروني خاطئ',
    'company-taken': 'الشركة/المؤسسة مسجلة مسبقاً',
};

const Signup = (props) => {
    const history = useHistory();
    const [newAccount, setNewAccount] = useState({
        company: '',
        name: '',
        email: '',
        password: ''
    });
    const [awsSES, setAwsSES] = useState('');
    const [awsCW, setAwsCW] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const auth = getAuth();
    const db = getFirestore();

    const firebaseConfigSecondary = {
        apiKey: "AIzaSyCPRnE1krVgrLxiQwYpya_GN4VMG0F3mrs",
        authDomain: "mujaz-platform.firebaseapp.com",
        projectId: "mujaz-platform",
        storageBucket: "mujaz-platform.appspot.com",
        messagingSenderId: "988604723058",
        appId: "1:988604723058:web:7cd884ca6736903381f7eb",
        measurementId: "G-H9L2H43NBN"
    };
    // Initialize 2nd Firebase app
    const secondaryApp = initializeApp(firebaseConfigSecondary, "secondary");
    const secondaryAuth = getAuth(secondaryApp);

    let accountData =
    {
        personalProfile: {
            personalData:
            {
                username: newAccount.email,
                name: newAccount.name,
                email: newAccount.email
            },
            companyData:
            {
                company: newAccount.company,
                website: '',
                address: '',
                city: '',
                country: '',
            }
        },
        settings:
        {
            senderSettings:
            {
                name: '',
                email: '',
                toPreview: '',
                defaultFrom: '',
                defaultReplyTo: '',
                aws: {
                    ses: '',
                    cw: ''
                }
            },
            view:
            {
                logo: '',
                primaryColor: '',
                secondaryColor: '',
            },
            footer:
            {
                footerLogo: '',
                text: '',
                slogon: '',
                copyrightText: '',
                unsubscribeText: '',
                socialMedia:
                {
                    facebook: '',
                    instagram: '',
                    twitter: '',
                    youtube: ''
                }
            },
            sponsorShip: {
                enabled: false,
                type: "manual"
            }
        }

    }


    const awsConfig = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    };
    AWS.config.update(awsConfig);
    const iam = new AWS.IAM();

    const createIAMUser = () => {
        let comp = newAccount.company.replace(/\s+/g, '-').toLowerCase()
        const params = {
            UserName: comp,
        };
        iam.createUser(params, (err, data) => {
            if (err) {
                console.log('Error creating user:', err);
            } else {
                console.log('User created:', data);

                // Attach the necessary policies for SES and CloudWatch
                const sesPolicyParams = {
                    PolicyArn: 'arn:aws:iam::aws:policy/AmazonSESFullAccess',
                    UserName: comp,
                };

                const cwPolicyParams = {
                    PolicyArn: 'arn:aws:iam::aws:policy/CloudWatchFullAccess',
                    UserName: comp,
                };

                iam.attachUserPolicy(sesPolicyParams, (err, data) => {
                    if (err) {
                        console.log('Error attaching SES policy to user:', err);
                    } else {
                        console.log('SES policy attached to user:', data);
                    }
                });

                iam.attachUserPolicy(cwPolicyParams, (err, data) => {
                    if (err) {
                        console.log('Error attaching CloudWatch policy to user:', err);
                    } else {
                        console.log('CloudWatch policy attached to user:', data);
                    }
                });

                const sesAccessKeyParams = {
                    UserName: comp,
                };

                const cwAccessKeyParams = {
                    UserName: comp,
                };

                //create access key for ses
                let accessSES = '';
                let accessCW = '';
                iam.createAccessKey(sesAccessKeyParams, (err, data) => {
                    if (err) {
                        console.log('Error creating SES access key:', err);
                    } else {
                        accessSES = data;
                        console.log('SES access key created:', data);
                        //create access key for cw
                        iam.createAccessKey(cwAccessKeyParams, (err, data) => {
                            if (err) {
                                console.log('Error creating CloudWatch access key:', err);
                            } else {
                                accessCW = data;
                                console.log('CloudWatch access key created:', data);
                                addCompanySettings(accessSES, accessCW);
                            }
                        });
                    }
                });
            }
        });
    }


    const addCompanySettings = async (accessSES, accessCW) => {
        await addDoc(collection(db, "Companies"), {
            personalProfile: {
                personalData:
                {
                    username: newAccount.email,
                    name: newAccount.name,
                    email: newAccount.email
                },
                companyData:
                {
                    company: newAccount.company,
                    website: '',
                    address: '',
                    city: '',
                    country: '',
                }
            },
            settings:
            {
                senderSettings:
                {
                    name: '',
                    email: '',
                    toPreview: '',
                    defaultFrom: '',
                    defaultReplyTo: '',
                    aws: {
                        ses: accessSES,
                        cw: accessCW
                    }
                },
                view:
                {
                    logo: '',
                    primaryColor: '',
                    secondaryColor: '',
                },
                sponsorShip: {
                    enabled: false,
                    type: 'auto'
                },
                footer:
                {
                    footerLogo: '',
                    text: '',
                    slogon: '',
                    copyrightText: '',
                    unsubscribeText: '',
                    socialMedia:
                    {
                        facebook: '',
                        instagram: '',
                        twitter: '',
                        youtube: ''
                    }
                },
                package: {
                    active: true,
                    sentEmails: 0,
                    totalEmails: 500,
                    type: 'free'
                }
            }

        })
            .then(() => {
                console.log('settings added');
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const addNewUser = async (uid) => {
        await addDoc(collection(db, "Users"), {
            company: newAccount.company,
            email: newAccount.email,
            logo: '',
            _2fa: {
                enabled: false,
                secret: ''
            },
            name: newAccount.name,
            responsibility: 'manager',
            uid: uid
        })
            .then((data) => {
                console.log('settings added');
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const isAccountComplete = (account) => {
        return Object.values(account).every(value => value !== '');
    }
    const onSubmit = async () => {
        try {
            setIsLoading(true);
            await fetch('https://us-central1-mujaz-platform.cloudfunctions.net/registerNewCompany', {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin':'https://mujaz.io',
                    'Access-Control-Allow-Methods':'post',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers':'Content-Type, Authorization',
                },
                body: JSON.stringify({
                    company: newAccount.company,
                    name: newAccount.name,
                    email: newAccount.email,
                    password: newAccount.password
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then(data => {
                    // Reset form after successful submission
                    setNewAccount({
                        company: '',
                        name: '',
                        email: '',
                        password: ''
                    });

                    // sendSignInLinkToEmail(auth, data.user.email)
                    //     .then((data) => {
                    //         console.log(data);
                    //         setIsLoading(false);
                    //         setSuccessMessage('تم إرسال البريد الإلكتروني الخاص بك للتحقق من ملكيته');
                    //     })
                    //     .catch((error) => {
                    //         console.log(error);
                    //         setErrorMessage(errorMessages[error.code]);
                    //         setIsLoading(false);
                    //     });
                    setIsLoading(false);
                    history.push('/login');
                })
                .catch(error => {
                    console.log('error: ' + error);
                })
        } catch (error) {
            setIsLoading(false);
            console.error('Error adding new account:', error);
        }


        
    };

    return (
        <>
            <section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
                data-image-src="../Public/assets/img/mujaz/mujaz-bg.jpg"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="display-1 mb-3">تسجيل حساب جديد</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item white-text"><a href="#">الرئيسية</a></li>
                                    <li className="breadcrumb-item active white-text" aria-current="page">تسجيل حساب جديد</li>
                                </ol>
                            </nav>

                        </div>

                    </div>

                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                            <div className="card">
                                <div className="card-body p-11 text-center">

                                    <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography variant='body2' className="lead mb-6 text-end">الرجاء إدخال المعلومات المطلوبة</Typography>
                                        </Grid>
                                        {Boolean(errorMessage) && (
                                            <Alert variant="standard" color="error" style={{ width: '100%' }}>
                                                <Typography>{errorMessage}</Typography>
                                            </Alert>
                                        )}
                                        {Boolean(successMessage) && (
                                            <Alert variant="standard" color="success" style={{ width: '100%' }}>
                                                <Typography>{successMessage}</Typography>
                                            </Alert>
                                        )}
                                        <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="company"
                                                label="الشركة/المؤسسة"
                                                autoComplete="company"
                                                value={newAccount.company}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    company: value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="name"
                                                label="الإسم الكامل"
                                                autoComplete="name"
                                                value={newAccount.name}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    name: value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="email"
                                                label="البريد الالكتروني"
                                                autoComplete="email"
                                                value={newAccount.email}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    email: value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="كلمة السر"
                                                type="password"
                                                id="password"
                                                autoComplete="current-password"
                                                value={newAccount.password}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    password: value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <LoadingButton
                                                className="btn btn-primary  btn-login w-100 mb-2"
                                                variant="contained"
                                                onClick={() => onSubmit()}
                                                loading={isLoading}
                                                disabled={isLoading}
                                            >تسجيل حساب جديد</LoadingButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link href="/login" variant="body2" style={{ textDecoration: 'none' }} className="hover">
                                                تسجيل الدخول
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    {/* <p className="mb-0">ليس لديك حساب؟ <a href="./signup.html" className="hover">تسجيل حساب جديد</a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const dispatchCompany = (dispatch) => {
    return {
        setCompany: (company, id, data, loggedIn) => { dispatch({ type: 'SET_COMPANY', value: company, id: id, data: data, loggedIn: loggedIn }) }
    }
}

export default connect(null, dispatchCompany)(Signup);
