import React, { useEffect, useCallback, useState } from 'react';
import {
    Button,
    Grid,
    Paper,
    IconButton,
    Typography,
    Snackbar,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, limit, startAfter, getDocs, setDoc, collection, query, where, serverTimestamp } from 'firebase/firestore';
import AddEditSubscriber from '../../components/AddEditSubscriber';
import { confirm } from "react-confirm-box";
import { connect } from 'react-redux';
import csv from 'csv';
import { ExportToCsv } from 'export-to-csv';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Subscribers = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [newSubVisible, setNewSubVisible] = useState(false);
    const [SubVisible, setSubVisible] = useState([]);
    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [lastDocument, setLastDocument] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit] = useState(10);
    const [forwarddDisabled, setForwarddDisabled] = useState(false);
    const [fetchFinish, setFetchFinish] = useState({ maxPage: 0, isFinish: false });

    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633',
                // padding: '20px'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            menuBtns: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            tableHeader: {
                // boxShadow: '1px 2px 5px #ddd',
                borderRadius: '10px',
                marginBottom: '20px',
                marginTop: '25px',
                padding: '15px',
                // background: '#eee',
            },
            pagination: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
                padding: 20,
                '& svg': {
                    marginRight: 20,
                    cursor: 'pointer',
                },
                '& svg:first-of-type': {
                    marginRight: 0
                }
            },
            backward: {
                fill: currentPage === 1 ? '	#A9A9A9' : '#000'
            },
            forward: {
                fill: forwarddDisabled ? '	#A9A9A9' : '#000'
            },

            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#f27021'
            }

        })
    );
    const fileInput = React.useRef();
    const { classes } = useStyles();

    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };



    const getDetails = async () => {
        const subRef = collection(db, "Subscribers");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            console.log(doc.data());
            setItems(items => [...items, {
                id: doc.id,
                company: doc.data().company,
                name: doc.data().name,
                email: doc.data().email
            }]);
            setSubVisible(prev => [...prev, false]);
        });




        setIsLoading(false);
    };

    const init = useCallback(async () => {
        let tempUsers = [];
        let q = query(collection(db, 'Subscribers'), where("company", "==", company), limit(10));
        const querySnapshot = await getDocs(q);

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastDocument(lastVisible);
        querySnapshot.forEach((entities) => {
            // doc.data() is never undefined for query doc snapshots
            tempUsers.push({ key: entities.id, ...entities.data() });
        });
        setItems(tempUsers);
    }, [db]);

    const handlePaginate = async (index) => {
        let tempUsers = [];
        let querySnapshot;
        let page = currentPage;
        let nextQuery;

        if (index) {
            
            if (!fetchFinish.isFinish) {
                nextQuery = query(collection(db, 'Subscribers'), where("company", "==", company), startAfter(lastDocument), limit(10));
                querySnapshot = await getDocs(nextQuery);
                ++page;

                if (querySnapshot.docs.length) {
                    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    setLastDocument(lastVisible);

                    querySnapshot.forEach((entities) => {
                        tempUsers.push({ key: entities.id, ...entities.data() });
                    });
                    setCurrentPage(page);
                    setItems([...items, ...tempUsers]);
                    forwarddDisabled && setForwarddDisabled(false);
                } else {
                    setFetchFinish({ maxPage: page, isFinish: true });
                    setForwarddDisabled(true);
                }
            } else {
                page !== fetchFinish.maxPage && ++page;
                setCurrentPage(page);
                page === fetchFinish.maxPage - 1 && !forwarddDisabled && setForwarddDisabled(true);
            }

        } else {
            
            --page;
            setCurrentPage(page);
            forwarddDisabled && setForwarddDisabled(false);
        }

    }

    const handleSubVisible = (index) => {
        let arr = [...SubVisible];
        arr[index] = !arr[index];
        setSubVisible(arr);
    };

    const handleAddSubscriber = async (name, email, id, index) => {

        if (id === '') {
            console.log(name);
            const docRef = await addDoc(collection(db, "Subscribers"), {
                name: name,
                email: email,
                company: company,
                createdAt: serverTimestamp()
            }).then((data) => {
                setItems(items => [...items, {
                    id: data.id,
                    company: company,
                    name: name,
                    email: email
                }]);
                setNewSubVisible(prev => !prev);
                setSnackBar({
                    isOpen: true,
                    message: 'تم إضافة المشترك بنجاح',
                });
            });
        } else {
            await setDoc(doc(db, 'Subscribers', id), {
                name: name,
                email: email,
                company: company
            }).then(() => {
                let newItemObj = items.map(obj => {
                    if (obj.id == id) {
                        return {
                            id: id,
                            name: name,
                            email: email,
                            company: company
                        }
                    }
                    return obj;
                });
                setItems(newItemObj);
                handleSubVisible(index);
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل المشترك بنجاح',
                });
            });
        }

    }

    const handleDeleteSubscriber = async (i) => {
        const result = await confirm("هل حقا تريد حذف هذا المشترك؟", options);
        if (result) {

            await deleteDoc(doc(db, "Subscribers", i.key)).then(() => {
                console.log('deleted: ' + i.key);
                setItems(items.filter(item => item.key !== i.key));
                setSnackBar({
                    isOpen: true,
                    message: 'تم مسح المشترك بنجاح',
                });
            });
        }
    }

    useEffect(() => {
        init();
    }, [init]);


    const uploadSubscribers = async () => {
        if (document.getElementById('csv-file').files.length > 0) {
            if (document.getElementById('csv-file').files[0].type == 'text/csv') {
                let file = document.getElementById('csv-file').files[0];
                const result = await confirm("هل حقا تريد إدخال مشتركين جدد؟", options);
                if (result) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        csv.parse(reader.result, (err, data) => {
                            var userList = [];
                            for (var i = 1; i < data.length; i++) {
                                const email = data[i][0];
                                const company = data[i][1];
                                const newUser = { "email": email, "company": company, "name": '' };
                                const docRef = addDoc(collection(db, "Subscribers"), newUser).then((data) => {
                                    console.log("Document written with ID: ", data.id);
                                    // setItems(items => [...items, {
                                    //     id: data.id,
                                    //     company: company,
                                    //     name: '',
                                    //     email: email
                                    // }]);
                                });
                            };
                            console.log('subscribers imported successfully');
                            setSnackBar({
                                isOpen: true,
                                message: 'تم إستيراد المشتركين بنجاح',
                            });
                        });

                    };

                    reader.readAsBinaryString(file);
                }
            } else {
                console.log('invalid format');
            }
        }
    }
    const exportSubscribers = async () => {
        const result = await confirm("هل حقا تريد تحميل المشتركين؟", options);
        if (result) {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'My Awesome CSV',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: company
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(items);
            setSnackBar({
                isOpen: true,
                message: 'تم تحميل المشتركين بنجاح',
            });
        }
    }
    return (
        <>
            <input
                ref={fileInput}
                type="file"
                id="csv-file"
                onChange={() => uploadSubscribers()}
                style={{ display: 'none' }}
            />
            <Grid container style={{ flexDirection: 'row-reverse', marginBottom: 20 }}>
                <Grid item>
                    <Button size='small' variant="contained" onClick={() => setNewSubVisible(prev => !prev)}>
                        إضافة مشترك
                    </Button>
                </Grid>
                <Grid item mr={1}>
                    <Button size='small' variant="contained" onClick={() => fileInput.current.click()} startIcon={<FileUploadIcon />}>
                        إستيراد مشتركين
                    </Button>
                </Grid>
                <Grid item mr={1}>
                    <Button size='small' variant="contained" onClick={() => exportSubscribers()} startIcon={<FileDownloadIcon />}>
                        تحميل مشتركين
                    </Button>
                </Grid>
            </Grid>


            {
                newSubVisible && <AddEditSubscriber
                    name=""
                    email=""
                    id=""
                    buttonName="إضافة"
                    handleAddSubscriber={handleAddSubscriber} />
            }
            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    البريد اللإلكتروني
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    إجراءات
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => {
                            if (index < currentPage * currentLimit && (currentPage === 1 ? true : index >= currentPage * currentLimit - 10)) {
                                return (
                                    <>
                                        <TableRow
                                            key={index}
                                            index={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton color='primary' size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteSubscriber(row)}>
                                                    <Delete fontSize='small' />
                                                </IconButton>
                                                <IconButton color='primary' size='small' aria-label="edit" className={classes.btns} onClick={() => handleSubVisible(index)}>
                                                    <Edit fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        {SubVisible[index] && <AddEditSubscriber
                                            name={row.name}
                                            email={row.email}
                                            id={row.id}
                                            index={index}
                                            handleAddSubscriber={handleAddSubscriber}
                                            buttonName="حفظ" />}
                                    </>

                                )
                            }
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={classes.pagination}>
                <IconButton disabled={currentPage === 1} onClick={() => handlePaginate(0)}>
                    <ArrowForwardIosIcon className={classes.backward} />
                </IconButton>
                <IconButton disabled={forwarddDisabled} onClick={() => handlePaginate(1)}>
                    <ArrowBackIosIcon className={classes.forward} />
                </IconButton>
            </div>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(Subscribers);
