import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Snackbar,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where, serverTimestamp } from 'firebase/firestore';


const SubscribersIntegration = () => {
    const { company } = useParams();
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const db = getFirestore();


    const onSubmit = async (event) => {
        event.preventDefault();
        if (email === '') {
            return;
        }
        const emailPattern = /\S+@\S+\.\S+/;
        const isEmail = emailPattern.test(email);
        if (!isEmail) {
            setSnackBar({
                isOpen: true,
                message: 'عنوان البريد الإلكتروني غير صالح',
            });
            //pop up error please fill a valid email
            return;
        }
        const check = collection(db, "SUB");
        const emailQuery = query(check, where("company", "==", company));
        let emails = await getDocs(emailQuery);
        if (emails.size > 0) {
            setSnackBar({
                isOpen: true,
                message: 'عنوان البريد الإلكتروني مشترك مسبقًا',
            });
            return;
        } 
        const docRef = await addDoc(collection(db, "Subscribers"), {
            email: email,
            company: company,
            createdAt: serverTimestamp()
        }).then((data) => {
            setEmail('');
            setSnackBar({
                isOpen: true,
                message: 'تم الاشتراك في البريد الإلكتروني بنجاح',
            });
        });


    }


    useEffect(async () => {
        const categoriesRef = collection(db, "Companies");
        const q = query(categoriesRef, where("personalProfile.companyData.company", "==", company));
        let data = await getDocs(q);
        if (data.size > 0) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, []);



    return (
        <>
            {visible && <form onSubmit={onSubmit}>
                <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 2 }}>
                    <Grid item xs={1} sm={1} md={1}>
                        <input
                            required
                            style={{ width: '100%' }}
                            type='email'
                            placeholder='Enter your email address'
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                        <input type='submit' value="Subscribe" />
                    </Grid>
                </Grid>
            </form>}
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
};
export default SubscribersIntegration;
