import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Select,
    MenuItem
} from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';

import { makeStyles } from "tss-react/mui";
import { isEmpty } from 'lodash';
import ImageUploaderProfile from './ImageUploaderProfile';


const AddEditUser = ({ buttonName, name, email, responsibility, logo, id, uid, index, handleAddUser, handleCancelAddUser }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState(name);
    const [userEmail, setUserEmail] = useState(email);
    const [userPassword, setUserPassword] = useState('');
    const [userResponsibility, setUserResponsibility] = useState(responsibility);
    const [image, setImage] = useState(logo);
    // const [visible, setVisible] = useState(false);
    const db = getFirestore();

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px',
                display: 'flex',
                flexDirection: 'column',
                // boxShadow: '0px 3px 6px #ccc',
                // background: '#fcfcfc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },


        })
    );

    const { classes } = useStyles();


    return (
        <>
            <Grid container spacing={2} mb={2} columns={{ xs: 1, sm: 1, md: 12 }} style={{ marginRight: 0, width: '100%' }}>
                <Paper elevation={0} className={classes.textPaper}>
                    <Grid container spacing={4} style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                        <Grid item xs={7}>
                            <Grid item className={classes.addCatForm}>
                                <TextField
                                fullWidth
                                    size='small'
                                    label='الإسم'
                                    id="outlined-name"
                                    margin="dense"
                                    index={index}
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </Grid>
                            <Grid item className={classes.addCatForm} mt={2}>
                                <Select
                                fullWidth
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    margin="dense"
                                    index={index}
                                    value={userResponsibility}
                                    label="المسؤولية"
                                    onChange={(e) => setUserResponsibility(e.target.value)}
                                >
                                    <MenuItem value='manager'>manager</MenuItem>
                                    <MenuItem value='editor'>Editor</MenuItem>
                                </Select>
                            </Grid>
                            {uid == '' && <Grid item className={classes.addCatForm} mt={2}>
                                <TextField
                                fullWidth
                                    size='small'
                                    label='البريد اللإلكتروني'
                                    id="outlined-name"
                                    margin="dense"
                                    index={index}
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                />
                            </Grid>}
                            {id == '' &&
                                <Grid item className={classes.addCatForm} mt={2}>
                                    <TextField
                                    fullWidth
                                        size='small'
                                        label='كلمة المرور'
                                        id="outlined-name"
                                        margin="dense"
                                        index={index}
                                        value={userPassword}
                                        onChange={(e) => setUserPassword(e.target.value)}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={4} columns={{xs:1, sm: 1, md: 1}}>
                                <Grid item xs={1} sm={1} md={1}>
                                    <ImageUploaderProfile image={image} onChange={setImage} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <p style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '0.5' }}>{userName}</p>
                                    <p style={{ color: '#eee', textAlign: 'center', lineHeight: '0.5' }}>{userResponsibility}</p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Paper>
            </Grid >
            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        size='small'
                        variant="outlined"
                        onClick={handleCancelAddUser} >
                        رجوع
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        size='small'
                        variant="contained"
                        onClick={e => handleAddUser(userName, userResponsibility, userEmail, userPassword, image, id, uid, index)} >
                        {buttonName}
                    </Button>
                </Grid>
            </Grid>
        </>

    );
};

export default AddEditUser;
