import {
    Autocomplete,
    Button,
    Paper,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import RichTextEditor from '../../atoms/RichTextEditor';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';

const WeatherRow = ({ onChange, onDelete, id, item }) => {
    const [weather, setWeather] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                low: 0,
                high: 0,
            }
    );

    useEffect(() => {
        onChange(weather);
    }, [weather]);

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
                options={[
                    '☁️',
                    '☂️',
                    '☔',
                    '🌁',
                    '❄️',
                    '🌫️',
                    '🌨️',
                    '🌧️',
                    '⛈️',
                    '🌩️',
                    '🌪️',
                    '⛅',
                    '☀️',
                    '🥵',
                    '🥶',
                    '💨',
                ]}
                disableClearable
                onChange={(e, icon) => setWeather({ ...weather, icon })}
                renderInput={(params) => <TextField {...params} label="الرمز التعبيري" />}
                value={weather.icon}
            />
            <TextField
                fullWidth
                label="المدينة"
                placeholder="المدينة"
                variant="outlined"
                value={weather.name}
                onChange={({ target: { value } }) => setWeather({ ...weather, name: value })}
            />
            <TextField
                label="الحرارة القصوى"
                placeholder="الحرارة القصوى"
                fullWidth
                variant="outlined"
                value={weather.high}
                onChange={({ target: { value } }) => setWeather({ ...weather, high: value })}
            />
            <TextField
                label="أدنى درجة حرارة"
                placeholder="أدنى درجة حرارة"
                fullWidth
                variant="outlined"
                value={weather.low}
                onChange={({ target: { value } }) => setWeather({ ...weather, low: value })}
            />
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
};

const ConfirmWeatherCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmWeatherCard }) => {
    const [weather, setWeather] = useState(
        defaultValue
            ? defaultValue.items
            : [
                {
                    key: generateKey(),
                    name: '',
                    icon: '',
                    low: 0,
                    high: 0,
                },
            ]
    );
    const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([{
        items: weather,
        body: body,
        category: 'توقعات الطقس',
        title: 'الطقس',
        date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`
    }]);
    const { data } = props;

    const previewCompRef = useRef();
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#fff',
                borderRadius: '25px',
                padding: '25px',
            }
        })
    );
    const PreviewFn = () => {
        setItems([{
            items: weather,
            body: body,
            category: 'توقعات الطقس',
            title: 'الطقس',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    const { classes } = useStyles();

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);
    return (
        <>
            <Grid container spacing={4} mt={1} style={{ marginRight: 0, width: '100%' }}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">توقعات الطقس</Typography>
                        <Stack direction="column" divider={<Divider />} spacing={2}>
                            {weather.map((city, index) => (
                                <WeatherRow
                                    key={city.key}
                                    id={city.key}
                                    onChange={(newCity) => {
                                        let temp = [...weather];
                                        temp.splice(index, 1, newCity);
                                        setWeather(temp);
                                    }}
                                    item={city}
                                    onDelete={() => {
                                        let temp = [...weather];
                                        temp.splice(index, 1);
                                        setWeather(temp);
                                    }}
                                />
                            ))}
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() =>
                                setWeather([
                                    ...weather,
                                    {
                                        key: generateKey(),
                                        name: '',
                                        icon: '',
                                        low: 0,
                                        high: 0,
                                    },
                                ])
                            }
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                        <RichTextEditor defaultValue={body} onChange={setBody} />
                    </Stack>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmWeatherCard(weather, body, defaultValue.date)}
                        >
                            تأكيد
                        </Button>
                        <Button variant="contained" className={classes.previewBtn} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                    </Grid>
                </Grid>

            </Grid >
        </>


    );
};

export default ConfirmWeatherCard;
