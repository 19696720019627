import React, { useEffect, useState } from 'react';
import {
    TextField,
    Grid,
    Snackbar,
    Box,
    Paper,
    Button,
    Typography,
    IconButton
} from '@mui/material';
import { Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import { LoadingButton } from '@mui/lab';
import { getFirestore, documentId, addDoc, setDoc, deleteDoc, getDocs, getDoc, doc, query, where, collection } from 'firebase/firestore';
import Post from '../../atoms/Post';
import { ArrowForwardIosTwoTone } from '@mui/icons-material';
import { queryAllByDisplayValue } from '@testing-library/react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SortIcon from '@mui/icons-material/Sort';
import { confirm } from "react-confirm-box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';

const NewslettersArchive = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const db = getFirestore();
    const history = useHistory();
    const useStyles = makeStyles()(
        () => ({
            container: {
                backgroundColor: '#FFF',
                marginTop: 40,
                paddingTop: 45,
                paddingBottom: 32
            },
            heading: {
                fontSize: '2em',
                fontWeight: 'bold',
                color: '#F77C15'
            },
            seeMore: {
                cursor: 'pointer',
                fontSize: 30,
                marginTop: 45
            },
            postContainer: {
                maxWidth: 1200,
                '@media only screen and (max-width: 600px)': {
                    display: 'flex',
                    justifyContent: 'center'
                },
                '@media only screen and (min-width: 1200px)': {
                    padding: '0 80px'
                },
            },
            paper: {
                width: 250,
                height: 250
            },
            cardBtns: {
                boxSizing: 'border-box',
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginTop: '15px',
            },
            editBtn: {
                background: '#f63',
                color: '#FFF',
                width: '120px',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px #00000026',
            },
            deleteBtn: {
                width: '120px',
                borderRadius: '30px',
                background: '#FFF',
                color: '#f63',
                boxShadow: '0px 3px 6px #00000026',
            },
            menuSubmitBtn: {
                background: '#fd7422',
                color: '#FFF',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            datePicker: {
                '&>.react-datepicker-wrapper input': {
                    border: '1px solid !important'
                }
            }
        })
    );
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container className={classes.confirmContainer}>
                    <Grid item className={classes.confirmText}>
                        <Typography variant="h5">{message}</Typography>
                    </Grid>
                    <Grid item className={classes.confirmAction}>
                        <Button variant="contained" className={classes.confirmBtnConfirm} onClick={onCancel}> تراجع </Button>
                        <Button variant="contained" className={classes.confirmBtnCancel} onClick={onConfirm}> تأكيد </Button>
                    </Grid>



                </Grid>
            );
        }
    };
    let from_date = new Date();
    const { classes } = useStyles();
    const [selectedNewsletter, setSelectedNewsletter] = useState([]);
    const [fromDate, setFromDate] = useState(new Date(from_date.setDate(from_date.getDate() - 7)));
    const [toDate, setToDate] = useState(new Date());
    const { company, data } = props;

    function _formatDate(dateIn, symbol) {
        let yyyy = dateIn.getFullYear();
        let mm = dateIn.getMonth() + 1;
        if (symbol != '-') {
            mm = mm.toString().padStart(2, '0');
        }
        let dd = dateIn.getDate();
        return String(dd + symbol + mm + symbol + yyyy);
    }
    const goToNewsletterDetails = async (id) => {
        history.push(`/newsletter/${id}`);
    }
    const fetchNewsletter = async () => {
        let dates = [];
        setSelectedNewsletter([]);
        let Difference_In_Time = toDate.getTime() - fromDate.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        {
            [...Array(Math.round(Difference_In_Days + 1))].map((item, i) => {
                let date = new Date();
                date.setDate(toDate.getDate() - i);
                date.setMonth(toDate.getMonth());
                date = _formatDate(date, '-');
                dates.push(company + "_" + date);

            })
            if (Difference_In_Days == 0) {
                let date = new Date();
                date.setDate(toDate.getDate());
                date = _formatDate(date, '-');
                dates.push(company + "_" + date);
            }
        }
        const q = query(collection(db, "NewslettersArchive"), where(documentId(), "in", dates));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.data());
            if (doc.data()) {
                let hide = doc.data().hide;
                let selected_title = doc.data().subject;
                setSelectedNewsletter(selectedNewsletter => [...selectedNewsletter, {
                    date: doc.id,
                    id: doc.id,
                    title: selected_title,
                    hide: hide
                }])
            }
        });

    }

    const hideNewsletter = async (id, i) => {
        const q = query(collection(db, "NewslettersArchive"), where(documentId(), "==", id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((value, index) => {
            setDoc(doc(db, 'NewslettersArchive', id), {
                hide: true,
                subject: value.data().subject,
                text: value.data().text,
                items: value.data().items,
                html: value.data().html
            }).then(() => {
                setSelectedNewsletter(selectedNewsletter.map((item, _i) => {
                    if (i === _i) {
                        return { ...item, hide: true };
                    }
                    return item;
                }))
            });
            setSnackBar({
                isOpen: true,
                message: 'تم إخفاء النشرة بنجاح',
            });
        })
    }
    const showNewsletter = async (id, i) => {
        const q = query(collection(db, "NewslettersArchive"), where(documentId(), "==", id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((value, index) => {
            setDoc(doc(db, 'NewslettersArchive', id), {
                hide: false,
                subject: value.data().subject,
                text: value.data().text,
                items: value.data().items,
                html: value.data().html
            }).then(() => {
                setSelectedNewsletter(selectedNewsletter.map((item, _i) => {
                    if (i === _i) {
                        return { ...item, hide: false };
                    }
                    return item;
                }))
                setSnackBar({
                    isOpen: true,
                    message: 'تم إظهار النشرة بنجاح',
                });
            });

        })
    }

    const deleteNewsletter = async (id) => {
        const result = await confirm("هل حقا تريد حذف هذه النشرة؟", options);
        if (result) {
            await deleteDoc(doc(db, "NewslettersArchive", id)).then(() => {
                console.log('deleted: ' + id);
                setSelectedNewsletter(selectedNewsletter.filter(item => item.id !== id));
                setSnackBar({
                    isOpen: true,
                    message: 'تم مسح النشرة بنجاح',
                });
            });
        }
    }
    useEffect(() => {
        fetchNewsletter();
    }, []);

    return (
        <>
            <Grid container spacing={2} mb={4} columns={{ xs: 1, sm: 1, md: 12 }} style={{ justifyContent: 'flex-end' }}>
                <Grid item xs={1} sm={1} md={8}>
                    <Paper elevation={0} style={{
                        boxShadow: 'none',
                        padding: '10px',
                        background: '#fff',
                    }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={1} sm={1} md={1}>
                                <Button variant="contained" onClick={() => fetchNewsletter()} >عرض</Button>
                            </Grid>
                            <Grid item xs={1} sm={1} md={4}>
                                <Grid container columns={{ xs: 1, sm: 1, md: 4 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <p style={{ textAlign: 'left', paddingLeft: '5px', marginBottom: 0 }}>من</p>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <DatePicker
                                            className={classes.datePicker}
                                            placeholderText="من"
                                            id="from"
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            selectsStart
                                            startDate={fromDate}
                                            endDate={toDate}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={4}>
                                <Grid container columns={{ xs: 1, sm: 1, md: 4 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <p style={{ textAlign: 'left', paddingLeft: '5px', marginBottom: 0 }}>إلى</p>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <DatePicker
                                            id="to"
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            selectsEnd
                                            startDate={fromDate}
                                            endDate={toDate}
                                            minDate={fromDate}
                                            maxDate={fromDate.getTime() + (9 * 24 * 60 * 60 * 1000)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <CalendarMonthIcon />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
            {/* <Grid container mt={2} spacing={2} sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between'
            }}> */}
            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead >
                        <TableRow>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    عنوان النشرة
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    التاريخ
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    إجراءات
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedNewsletter?.map((row, index) => {
                            return (
                                <>
                                    <TableRow
                                        key={index}
                                        index={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.date.replaceAll(company + "_", '')}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton size='small' color="primary" aria-label="delete" className={classes.btns} onClick={() => deleteNewsletter(row.id)}>
                                                <Delete fontSize='small' />
                                            </IconButton>
                                            <IconButton size='small' color="primary" aria-label="edit" className={classes.btns} onClick={() => goToNewsletterDetails(row.id)}>
                                                <VisibilityIcon fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </>

                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* </Grid> */}
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
}
const mapStateToProps = (state) => {
    console.log(state);
    return state
}
export default connect(mapStateToProps)(NewslettersArchive);
