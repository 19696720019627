import { Stack, TextField, Grid, Button, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import ImageUploader from '../../components/ImageUploader';
import { generateKey } from '../../utils';
import SideBarMenu from '../../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import HTMLViewer from '../../components/HTMLViewer';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';

const NewsTwitter = () => {
    const [tweet, setTweet] = useState({
        id: '',
        username: '',
        url: ''
    });
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const previewCompRef = useRef();
    const db = getFirestore();
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            previewBtn: {
                backgroundColor: '#fff',
                color: '#ff6633',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            },
            saveBtn: {
                backgroundColor: '#ff6633',
                color: '#fff',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#fff',
                    color: '#ff6633',
                },
            },
            content: {
                background: '#f7f7f7',
                borderRadius: '25px',
                padding: '25px',
                boxShadow: '0px 3px 6px #ebebeb'
            }

        })
    );

    const { classes } = useStyles();

    const getTweetId = (defaultValue) => {
        const idSplit = defaultValue.split('/');
        const id = idSplit[idSplit.length - 1];
        const username = idSplit[3];
        setTweet({
            id: id,
            username: username,
            url: defaultValue
        });
    }

    const getDateId = (company) => {
        return `${company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };

    const saveNewsImage = async () => {
        // const docTitle = getDateId('mujaz');

        // let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        // if (data.data()) {
        //     await setDoc(doc(db, 'NewsLibrary', docTitle), {
        //         News: data.data().News,
        //         Stocks: data.data().Stocks,
        //         Weather: data.data().Weather,
        //         Image: {
        //             id: generateKey(),
        //             section: section,
        //             title: title,
        //             image: image,
        //             credits: credits,
        //             alt: alt,
        //             category: 'صورة',
        //             date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
        //                 }-${new Date().getFullYear()}`
        //         },
        //         Currency: data.data().Currency,
        //         data: data.data().data
        //     }).then((data) => {
        //         console.log('done update');
        //     });
        // } else {
        //     await setDoc(doc(db, 'NewsLibrary', docTitle), {
        //         News: [],
        //         Stocks: '',
        //         Weather: '',
        //         Image: {
        //             id: generateKey(),
        //             section: section,
        //             title: title,
        //             image: image,
        //             credits: credits,
        //             alt: alt,
        //             category: 'صورة',
        //             date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
        //                 }-${new Date().getFullYear()}`
        //         },
        //         Currency: '',
        //         data: {
        //             date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
        //                 }-${new Date().getFullYear()}`
        //         }
        //     }).then((data) => {
        //         console.log('done');
        //     });
        // }
    }
    const PreviewFn = () => {
        setItems([{
            tweet: tweet,
            category: 'تويتر',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (

        <>
            <Grid container spacing={5} mt={4}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">مشاركة تغريدة</Typography>
                        <Stack direction="column" spacing={1}>
                            <TextField
                                fullWidth
                                value={tweet.url}
                                onChange={({ target: { value } }) => getTweetId(value)}
                                variant="outlined"
                                label="رابط التغريدة"
                                placeholder="رابط التغريدة"
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Preview data={items} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" className={classes.previewBtn} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                        <Button variant="contained" className={classes.saveBtn} endIcon={<SaveIcon />} onClick={saveNewsImage}>حفظ في مكتبة الفئات</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default NewsTwitter;
