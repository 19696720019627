import { Stack, TextField, Grid, Paper, Button, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import ImageUploader from '../ImageUploader';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';


const ConfirmImageCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmImageCard }) => {
    const [section, setSection] = useState(
        defaultValue && defaultValue.section ? defaultValue.section : ''
    );
    const [title, setTitle] = useState(
        defaultValue && defaultValue.title ? defaultValue.title : ''
    );
    const [image, setImage] = useState(
        defaultValue && defaultValue.image ? defaultValue.image : ''
    );
    const [credits, setCredits] = useState(
        defaultValue && defaultValue.credits ? defaultValue.credits : ''
    );
    const [alt, setAlt] = useState(defaultValue && defaultValue.alt ? defaultValue.alt : '');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const previewCompRef = useRef();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#fff',
                borderRadius: '25px',
                padding: '25px',
            }
        })
    );

    const { classes } = useStyles();
    const PreviewFn = () => {
        setItems([{
            section: section,
            title: title,
            image: image,
            credits: credits,
            alt: alt,
            category: 'صورة',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (
        <>
            <Grid container spacing={4} mt={1} style={{ marginRight: 0, width: '100%' }}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">صورة</Typography>
                        <Stack direction="column" spacing={1}>
                            <TextField
                                fullWidth
                                value={section}
                                onChange={({ target: { value } }) => setSection(value)}
                                variant="outlined"
                                label="الفقرة"
                                placeholder="الفقرة"
                            />
                            <TextField
                                fullWidth
                                value={title}
                                onChange={({ target: { value } }) => setTitle(value)}
                                variant="outlined"
                                label="العنوان"
                                placeholder="العنوان"
                            />
                        </Stack>
                        <ImageUploader image={image} onChange={setImage} />
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                value={credits}
                                onChange={({ target: { value } }) => setCredits(value)}
                                variant="outlined"
                                label="المصدر"
                                placeholder="المصدر"
                            />
                            <TextField
                                fullWidth
                                value={alt}
                                onChange={({ target: { value } }) => setAlt(value)}
                                variant="outlined"
                                label="وصف الصورة"
                                placeholder="وصف الصورة"
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmImageCard(alt, credits, image, section, title, defaultValue)}
                        >
                            تأكيد
                        </Button>
                        <Button variant="contained" className={classes.previewBtn} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ConfirmImageCard;
