import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Avatar,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Chip,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where, limit } from 'firebase/firestore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import AddEditSponsor from './SponsorAdd';
import { confirm } from "react-confirm-box";
import moment from 'moment/moment';
import logo from '../../img/pic-03.jpg';


const SponsorList = (props) => {
    const history = useHistory();
    const { company } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [sponsors, setSponsors] = useState([]);

    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const db = getFirestore();

    const useStyles = makeStyles()(
        () => ({
            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#f27021'
            }

        })
    );

    const { classes } = useStyles();

    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const getSponsors = async () => {
        const subRef = collection(db, "Sponsors");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            setSponsors(prev => [...prev, doc.data()]);

            // setSponsorVisible(sponsorVisible => [...sponsorVisible, false]);
        });
        // setIsLoading(false);
    };
    const handleDeleteSponsor = async (id) => {

        const result = await confirm("هل حقا تريد حذف هذا الراعي؟", options);
        if (result) {
            const q = query(collection(db, "Sponsors"), where("id", "==", id), limit(1));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const docRef = doc(db, "Sponsors", querySnapshot.docs[0].id);
                deleteDoc(docRef);
                setSponsors(sponsors.filter(sponsor => sponsor.id !== id));
                console.log(`Document with name "${id}" and id "${doc.id}" successfully deleted`);
            }
        }
    }


    useEffect(() => {
        getSponsors();
    }, []);



    return (


        <>
            <Grid container style={{ flexDirection: 'row-reverse', marginBottom: 20 }}>
                <Grid item>
                    <Button variant="contained" onClick={() => {
                        history.push('/sponsor/add');
                    }
                    }>
                        إضافة جديد
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    الشعار
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    الصورة
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    الاسم
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    النوع
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    من
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    الى
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    الأيام
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography xs={3} variant="p" style={{ fontWeight: 600 }}>
                                    إجراءات
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sponsors?.map((row, index) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    index={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Avatar alt="Remy Sharp" src={row.logo} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Avatar alt="Remy Sharp" src={row.image} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.type}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {
                                            moment(new Date(row.dateFrom.seconds * 1000 + row.dateFrom.nanoseconds / 1000000)).format("YYYY-MM-DD")
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {
                                            moment(new Date(row.dateTo.seconds * 1000 + row.dateTo.nanoseconds / 1000000)).format("YYYY-MM-DD")
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.pattern.map((day, index) => (
                                            <Chip key={index} label={day.label} size="small" />
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton color="primary" size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteSponsor(row.id)}>
                                            <Delete fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    );
};
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(SponsorList);
