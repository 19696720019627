import React, { useEffect, useState } from 'react';
import {
    Grid
} from '@mui/material';
import { getDoc, getFirestore, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import { useHistory } from 'react-router-dom';


const NewsletterArchiveDetails = () => {

    const useStyles = makeStyles()(
        () => ({
            heading: {
                background: '#F77C15',
                borderBottomRightRadius: '50px',
                borderBottomLeftRadius: "50px",
                padding: '30px 80px',
                color: "#fff",
                display: 'flex',
                justifyContent: 'center',
                fontSize: '2em',
                marginBottom: 40,
                '@media only screen and (max-width: 750px)': {
                    fontSize: '1em'
                },
            },
            content: {
                minHeight: '50vh',
                '@media only screen and (max-width: 750px)': {
                    padding: '0px 20px',
                    display: 'flex',
                    minWidth: '100%',
                    '& *': {
                        fontFamily: 'unset',
                    },
                    '& a': {
                        // color: '#F77C15 !important'
                    }
                },
            }
        })
    );
    const [isLoading, setIsLoading] = useState(false);
    const { classes } = useStyles();
    const history = useHistory();

    const db = getFirestore();

    const scrollPage = (id, mb) => {
        if (id) {
            history.push(`/${id}/${mb ? mb : ''}`);
        }
    }

    const [html, setHtml] = useState('');
    const { id } = useParams();

    const fetchNewsletter = async (id) => {
        const docRef = doc(db, "NewslettersArchive", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists) {
            // console.log(docSnap.data().html);
            setHtml(docSnap.data().html);
            window.scrollTo(0, 0);
        }
    }


    useEffect(() => {
        if (id) {
            // console.log(id);
            fetchNewsletter(id);
        }
    }, [id])

    return (
        <Grid
            container
            sx={{
                backgroundColor: '#fefefe',
                justifyContent: 'center'
            }}
        >
            <Grid item xs={12} justifyContent={'center'} className={classes.content} dangerouslySetInnerHTML={{ __html: html }}></Grid>
        </Grid>
    );
};

export default NewsletterArchiveDetails;
