import React, { useState } from 'react';
import {
    TextField,
    Grid,
    // InputAdornment,
    Typography,
    Button,
    // Box
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
// import { LoadingButton } from '@mui/lab';
import { getFirestore, addDoc, getDocs, query, where, collection, serverTimestamp } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const Subscribe = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState({ status: 'pending', message: '' });

    const db = getFirestore();
    const { company } = useParams();
    console.log(company);

    const useStyles = makeStyles()(
        () => ({
            formGrid: {
                width: '100%',
                '@media only screen and (max-width: 900px)': {
                    width: '100%',
                    display: 'block',

                },
            },
            mobilePhone: {
                display: 'none',
                '@media only screen and (max-width: 900px)': {
                    width: '100%',
                    display: 'block',



                },
            },
            mobileImageDiv: {
                display: 'block',
                position: 'relative',
                height: 'auto'
            },
            banerInput: {
                borderRadius: 5,
                border: '1px solid #666',
                boxShadow: '0px 1px 3px #ccc',
                background: '#fff',
                paddingLeft: 0,
                marginLeft: 10,
                width: 500,
                '&>div': {
                    paddingLeft: 6,
                    '&>input': {
                        padding: 12
                    }
                },
                "& fieldset": {
                    border: "none",
                    outline: 'none'
                },
                '@media only screen and (max-width: 900px)': {
                    width: '100%',
                },
            },
            inputButton: {
                borderRadius: 5,
                width: 125,
                '@media only screen and (max-width: 900px)': {
                    width: '90%',
                    display: 'block',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginTop: 20
                },

            },
            helperText: {
                fontSize: '0.75em',
                color: '#000',
                marginTop: 8
            },
        })
    );

    const handleSubmit = async () => {
        setIsLoading(true);

        let q = query(collection(db, 'users'), where('email', '==', email));
        let searchForEmail = await getDocs(q);
        if (searchForEmail.size === 0) {
            await addDoc(collection(db, 'users'), { email: email, createdAt: serverTimestamp() });
            setStatus({
                status: 'success',
                message: 'تم تسجيل بريدك الاكتروني بنجاح! اهلًا وسهلًا بك الى عائلة موجز.',
            });
            setEmail("")
        } else {
            setStatus({
                status: 'error',
                message:
                    'بريدك الإلكتروني مسجل بالفعل في نظامنا. إذا كنت لا تتلقى رسائل البريد الإلكتروني الخاصة بنا ، فيرجى التحقق من مجلدات البريد العشوائي أو غير المرغوب فيه.',
            });
        }
        setIsLoading(false);
    };

    const onChange = (email) => {
        setEmail(email);
        if (status.message != '') {
            setStatus({
                status: 'pending',
                message: ''
            })
        }
    }

    const { classes } = useStyles();

    return (
        <Grid
            container
            
        >
            <Grid container className={classes.formGrid}>
                <Grid item>
                    <TextField
                        className={classes.banerInput}
                        disabled={isLoading}
                        onChange={({ target: { value } }) => onChange(value)}
                        value={email}
                        autoComplete="email"
                        placeholder="البريد الإلكتروني"
                    />
                </Grid>
                <Grid item alignItems="stretch" style={{ display: "flex" }}>
                    <Button className={classes.inputButton}
                        variant="contained"

                        onClick={handleSubmit}
                    >جربها الآن</Button>
                </Grid>
            </Grid>
            <Typography className={classes.helperText}>
                {status.message}
            </Typography>
        </Grid >
    );
};

export default Subscribe;
