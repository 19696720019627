import React, { useState, useEffect } from 'react';
import { Stack, TextField, IconButton, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const StockRow = ({ onChange, onDelete, id, item }) => {
	const [stock, setStock] = useState(
		item
			? item
			: {
					key: id,
					name: '',
					value: 0,
					change: '0',
			  }
	);

	const arrows = {
		positive: '🟢',
		negative: '🔴',
		zero: '🔵',
	};

	useEffect(() => {
		onChange(stock);
	}, [stock]);

	return (
		<Stack spacing={1} divider={<Divider />} direction="row" alignItems="center">
			{
				arrows[
					stock.change.indexOf('+') > 0
						? 'positive'
						: stock.change.indexOf('-') > 0
						? 'negative'
						: 'zero'
				]
			}
			<TextField
				fullWidth
				label="اسم السهم"
				placeholder="اسم السهم"
				variant="outlined"
				value={stock.name}
				onChange={({ target: { value } }) => setStock({ ...stock, name: value })}
			/>
			<TextField
				label="قيمة السهم"
				placeholder="قيمة السهم"
				fullWidth
				variant="outlined"
				value={stock.value}
				onChange={({ target: { value } }) => setStock({ ...stock, value: value })}
			/>
			<TextField
				label="تغيير السهم"
				placeholder="تغيير السهم"
				fullWidth
				variant="outlined"
				color={stock.change > 0 ? 'success' : stock.change < 0 ? 'error' : 'info'}
				value={stock.change}
				onChange={({ target: { value } }) => setStock({ ...stock, change: value })}
			/>
			<IconButton color='primary' onClick={onDelete}>
				<DeleteIcon />
			</IconButton>
		</Stack>
	);
};

export default StockRow;
