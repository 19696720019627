import React, { useEffect, useState } from 'react';
import { makeStyles } from "tss-react/mui";
import { useHistory } from 'react-router-dom';
import {
    Button,
    Autocomplete,
    Snackbar,
    Divider,
    Grid,
    Paper,
    Checkbox,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { Chip } from "@material-ui/core";
import { MultiSelect } from "react-multi-select-component";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { doc, addDoc, getDoc, getFirestore, getDocs, updateDoc, setDoc, collection, query, limit, where } from 'firebase/firestore';
import { generateKey } from '../../utils';
import { connect } from 'react-redux';
import _ from 'lodash';
import { confirm } from "react-confirm-box";
import ImageUploader from '../../components/ImageUploader';
import RichTextEditor from '../../atoms/RichTextEditor';
import moment from 'moment/moment';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SponsorAdd = (props) => {
    const { company } = props;
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const history = useHistory();
    const useStyles = makeStyles()(
        () => ({
            multiselect: {
                '&>div.dropdown-container': {
                    borderRadius: '10px',
                    height: '45px',
                    paddingTop: '2px'
                }
            },
            datepicker: {
                width: '100%',
                '&::placeholder': {
                    fontWeight: '400',
                    textAlign: 'right',
                    paddingRight: '10px'
                }
            },
            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important'
            },
            btns: {
                color: '#f27021'
            }
        })
    );
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const { classes } = useStyles();
    const [cost, setCost] = useState('0.00');
    const [excludedDates, setExcludedDates] = useState([]);
    const [disabledObj, setDisabledObj] = useState({
        datepickerFrom: true,
        datepickerTo: true,
        days: true
    });
    const [sponsor, setSponsor] = useState({
        id: generateKey(),
        company: company,
        name: '',
        type: '',
        dateFrom: null,
        dateTo: null,
        includedDates: [],
        excludeDates: [],
        pattern: [],
        body: '',
        logo: '',
        image: '',

    })
    // const [visible, setVisible] = useState(false);
    const db = getFirestore();
    const types = ['أساسي', 'ثانوي'];
    const [patterns, setPatterns] = useState([
        {
            label: 'الإثنين',
            value: 'Monday'
        },
        {
            label: 'الثلاثاء',
            value: 'Tuesday'
        },
        {
            label: 'الأربعاء',
            value: 'Wednesday'
        },
        {
            label: 'الخميس',
            value: 'Thursday'
        },
        {
            label: 'الجمعة',
            value: 'Friday'
        },
        {
            label: 'السبت',
            value: 'Saturday'
        },
        {
            label: 'الأحد',
            value: 'Sunday'
        }
    ]);
    const getDatesInRange = (from, to) => {
        const _dates = [];
        let currentDate = new Date(from);
        while (currentDate <= to) {
            _dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return _dates;
        // setSponsor(prevSponsor => ({
        //     ...prevSponsor,
        //     dates: {
        //         ...prevSponsor.dates,
        //         included: _dates
        //     }
        // }));
    }
    const calSponsorCost = async () => {
        const includedDates = getDatesInRange(sponsor.dateFrom, sponsor.dateTo);
        const sponsorWithDateInRange = { ...sponsor, includedDates: includedDates };
        let countNumerOfDays = 0;
        for (const includedDate of sponsorWithDateInRange.includedDates) {
            const options = { weekday: 'long' };
            const includedWeekDay = new Intl.DateTimeFormat('en-US', options).format(includedDate);
            sponsor.pattern.map((v) => {
                if (v.value.includes(includedWeekDay)) {
                    countNumerOfDays++;
                }
            })
        }
        const subRef = collection(db, "Subscribers");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        let cost;
        if (sponsor.type == types[0]) {
            cost = (data.size * countNumerOfDays) * 0.001 //every 1000 email for 1$ for type [0]
        } else {
            cost = (data.size * countNumerOfDays) * 0.0005 //every 2000 email for 1$ for type [1]
        }
        setCost(cost.toFixed(2));
    }
    const handleAddSponsor = async () => {
        calSponsorCost();
        const result = await confirm("هل حقا تريد إضافة راعي جديد؟", options);
        if (result) {
            if (
                _.isEmpty(sponsor.name)
                || _.isEmpty(sponsor.type)
                || sponsor.dateFrom == null
                || sponsor.dateTo == null
                || sponsor.pattern.length < 1
                || _.isEmpty(sponsor.body)
                || _.isEmpty(sponsor.logo)
                || _.isEmpty(sponsor.image)
            ) {
                setSnackBar({
                    isOpen: true,
                    message: 'الرجاء تعبئة جميع الحقول المطلوبة',
                });
                return;
            }
            const includedDates = getDatesInRange(sponsor.dateFrom, sponsor.dateTo);
            const sponsorWithDateInRange = { ...sponsor, includedDates: includedDates };

            await addDoc(collection(db, "Sponsors"), sponsorWithDateInRange)
                .then(async () => {
                    const companyQuery = query(collection(db, 'Companies'), where("personalProfile.companyData.company", "==", company), limit(1));
                    const companyDocs = await getDocs(companyQuery);
                    if (companyDocs.size > 0) {
                        const companyDoc = companyDocs.docs[0];
                        const companyData = companyDoc.data();
                        companyData.settings.sponsorShip.enabled = true;
                        companyData.settings.sponsorShip.type = "manual";
                        updateDoc(doc(db, 'Companies', companyDoc.id), companyData)
                            .then(() => {
                                setSponsor({
                                    id: generateKey(),
                                    company: company,
                                    name: '',
                                    type: '',
                                    dateFrom: null,
                                    dateTo: null,
                                    includedDates: [],
                                    excludeDates: [],
                                    pattern: [],
                                    body: '',
                                    logo: '',
                                    image: '',

                                })
                            })
                        setSnackBar({
                            isOpen: true,
                            message: 'تم إضافة الراعي بنجاح',
                        });
                        handleCancelAddSponsor();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const handleCancelAddSponsor = () => {
        history.push('/sponsor/list')
    }
    const checkDatePickerRanges = async () => {
        const subRef = collection(db, "Sponsors");
        const q = query(subRef,
            where("company", "==", company),
            where("type", "==", sponsor.type));
        let data = await getDocs(q);
        let excluded = [];
        data.forEach((doc) => {
            doc.data().includedDates.forEach((v) => {
                excluded.push(moment(new Date(v.seconds * 1000 + v.nanoseconds / 1000000)).format("MM/DD/YYYY"));
            })
        });
        setExcludedDates(excluded);
        setDisabledObj({
            ...disabledObj,
            datepickerFrom: false
        })
    }
    const checkWeekDaysRange = () => {
        let rangeOfFromToDates = getDatesInRange(sponsor.dateFrom, sponsor.dateTo);
        let formatedRangeOfFromTo = rangeOfFromToDates.map((v) => (
            moment(v).local().format("MM/DD/YYYY")
        ))
        //check disabled dates with dateFrom and dateTo
        const matchedDays = [];
        formatedRangeOfFromTo.forEach(FromTo => {
            const dateOneObj = new Date(FromTo);
            const matchingDate = excludedDates.find(excluded => {
                const dateTwoObj = new Date(excluded);
                return dateOneObj.getTime() === dateTwoObj.getTime();
            });
            if (matchingDate) {
                const options = { weekday: 'long' };
                const day = new Intl.DateTimeFormat('en-US', options).format(dateOneObj);
                matchedDays.push(day);
            }
        });
        const uniqueWeekdays = [...new Set(matchedDays)];
        // Check if all dates in formatedRangeOfFromTo are excluded
        const allDatesExcluded = formatedRangeOfFromTo.every(date => excludedDates.includes(date));
        const getFilteredPatterns = (weekdays) => {
            const originalPatterns = [
                {
                    label: 'الإثنين',
                    value: 'Monday'
                },
                {
                    label: 'الثلاثاء',
                    value: 'Tuesday'
                },
                {
                    label: 'الأربعاء',
                    value: 'Wednesday'
                },
                {
                    label: 'الخميس',
                    value: 'Thursday'
                },
                {
                    label: 'الجمعة',
                    value: 'Friday'
                },
                {
                    label: 'السبت',
                    value: 'Saturday'
                },
                {
                    label: 'الأحد',
                    value: 'Sunday'
                }
            ];
            const filteredPatterns = originalPatterns.filter((pattern) => {
                return !weekdays.includes(pattern.value);
            });
            return filteredPatterns;
        };
        let patterns;
        if (allDatesExcluded) {
            patterns = []; // return empty pattern
        } else {
            patterns = getFilteredPatterns(uniqueWeekdays);
        }
        setPatterns(patterns);
        setDisabledObj({
            ...disabledObj,
            days: false
        })
    }


    useEffect(() => {
        if (!_.isEmpty(sponsor.type)) {
            checkDatePickerRanges();
        }
    }, [sponsor.type])

    useEffect(() => {
        if (sponsor.dateTo != null) {
            checkWeekDaysRange();
        }
    }, [sponsor.dateTo])
    useEffect(() => {
        calSponsorCost();
    }, [sponsor.pattern, sponsor.type])
    return (
        <>
            <Grid container spacing={6} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item md={12}>
                    <Paper elevation={0} style={{ padding: '15px', marginBottom: '5px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={12}>
                                <Typography variant='body1'>التكلفة: {cost}$</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={0} style={{ padding: '30px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={6}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <TextField
                                            fullWidth
                                            id="outlined-name"
                                            variant='outlined'
                                            size='small'
                                            label="إسم الراعي"
                                            // index={index}
                                            value={sponsor.name}
                                            onChange={(e) => setSponsor({
                                                ...sponsor,
                                                name: e.target.value
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <Autocomplete
                                            fullWidth
                                            size='small'
                                            disablePortal
                                            onChange={(i, sponsorType) => {
                                                setSponsor({
                                                    ...sponsor,
                                                    type: sponsorType
                                                });
                                            }}
                                            value={sponsor.type}
                                            id="combo-box-demo"
                                            options={types}
                                            renderInput={(params) => <TextField size='small' {...params} label="نوع الراعي" />}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <DatePicker
                                            className={classes.datepicker}
                                            placeholderText="من"
                                            id="sponsor-from"
                                            selected={sponsor.dateFrom}
                                            onChange={(date) => {
                                                setSponsor({
                                                    ...sponsor,
                                                    dateFrom: date,
                                                    dateTo: null,
                                                    pattern: []
                                                });
                                                setDisabledObj({
                                                    ...disabledObj,
                                                    datepickerTo: false
                                                })
                                            }}
                                            disabled={disabledObj.datepickerFrom}
                                            selectsStart
                                            startDate={sponsor.dateFrom}
                                            endDate={sponsor.dateTo}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <DatePicker
                                            className={classes.datepicker}
                                            placeholderText="الى"
                                            id="sponsor-to"
                                            selected={sponsor.dateTo}
                                            onChange={(date) => {
                                                date.setHours(23);
                                                date.setMinutes(59);
                                                date.setSeconds(59);
                                                setSponsor({
                                                    ...sponsor,
                                                    dateTo: date,
                                                    pattern: []
                                                })
                                            }
                                            }
                                            disabled={disabledObj.datepickerTo}
                                            selectsEnd
                                            startDate={sponsor.dateFrom}
                                            endDate={sponsor.dateTo}
                                            minDate={sponsor.dateFrom}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <MultiSelect
                                            options={patterns}
                                            value={sponsor.pattern}
                                            onChange={(i, v) => setSponsor({ ...sponsor, pattern: i })}
                                            className={classes.multiselect}
                                            disabled={disabledObj.days}
                                            overrideStrings={{
                                                "allItemsAreSelected": "تم تحديد كل الأيام.",
                                                "clearSearch": "مسح البحث",
                                                "clearSelected": "مسح المحدد",
                                                "noOptions": "لا يوجد خيارات",
                                                "search": "بحث",
                                                "selectAll": "تحديد الكل",
                                                "selectAllFiltered": "اختر الكل (مصفى)",
                                                "selectSomeItems": "حدد الأيام",
                                                "create": "إنشاء",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <RichTextEditor value={sponsor.body} onChange={(value) => setSponsor({
                                            ...sponsor,
                                            body: value
                                        })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <ImageUploader
                                            textImage="قم/قومي بسحب وإسقاط صورة شعار الراعي هنا ، أو انقر/ي لتحديد صورة الشعار"
                                            image={sponsor.logo} onChange={(v) => setSponsor({
                                                ...sponsor,
                                                logo: v
                                            })} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12}>
                                        <ImageUploader
                                            textImage="قم/قومي بسحب وإسقاط صورة الراعي هنا ، أو انقر/ي لتحديد صورة الراعي"
                                            image={sponsor.image} onChange={(v) => setSponsor({
                                                ...sponsor,
                                                image: v
                                            })} />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}>
                                <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            onClick={handleCancelAddSponsor}
                                        >
                                            رجوع
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                        <Button
                                            size='small'
                                            variant="contained"
                                            onClick={handleAddSponsor} >
                                            إضافة
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid >
            </Grid >

            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />

        </>

    );
};

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(SponsorAdd);
