import React, { useEffect, useState } from 'react';
import {
	Link,
	Typography,
} from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HeaderNavBar from '../../components/HeaderNavBar';
import FooterNavBar from '../../components/FooterNavBar';
import "../Public/assets/css/style.css";
import "../Public/assets/css/colors/yellow.css";
import "../Public/assets/css/fonts/thicccboi.css";



function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" {...props}>
			{'جميع الحقوق محفوظة © '}
			<Link color="inherit" href="https://mujaz.io/">
				mujaz.io
			</Link>
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const Home = () => {
	const [priceRange, setPriceRange] = useState({
		cost: 0,
		emails: '500'
		// cost: 46,
		// emails: '50 ألف'
	});
	const [value, setValue] = useState(21);
	// const allowedSteps = [21, 38, 72, 119, 159, 299];
	const allowedSteps = [0, 46, 63, 97, 144, 184, 324];
	const rangeInput = (value) => {
		let closestStep = allowedSteps.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));
		switch (closestStep) {
			case 0:
				setPriceRange({
					cost: closestStep,
					emails: '500'
				});
				break;
			case 46:
				setPriceRange({
					cost: closestStep,
					emails: '50 ألف'
				});
				break;
			case 63:
				setPriceRange({
					cost: closestStep,
					emails: '100 ألف'
				});
				break;
			case 97:
				setPriceRange({
					cost: closestStep,
					emails: '200 ألف'
				});
				break;
			case 144:
				setPriceRange({
					cost: closestStep,
					emails: '350 ألف'
				});
				break;
			case 184:
				setPriceRange({
					cost: closestStep,
					emails: '500 ألف'
				});
				break;
			case 324:
				setPriceRange({
					cost: closestStep,
					emails: 'مليون'
				});
				break;
		}




		// 	input.addEventListener("input", function (event) {
		// 		let value = Number(event.target.value);

		// 		input.value = closestStep;
		// 		switch (input.value) {
		// 			case 21:
		// 				setPriceRange({
		// 					cost: 21,
		// 					emails: '0-50K'
		// 				});
		// 				break;
		// 			case 38:
		// 				setPriceRange({
		// 					cost: 38,
		// 					emails: '50-100K'
		// 				});
		// 				break;
		// 			case 72:
		// 				setPriceRange({
		// 					cost: 72,
		// 					emails: '100-200K'
		// 				});
		// 				break;
		// 			case 119:
		// 				setPriceRange({
		// 					cost: 119,
		// 					emails: '200-350K'
		// 				});
		// 				break;
		// 			case 159:
		// 				setPriceRange({
		// 					cost: 159,
		// 					emails: '350-500K'
		// 				});
		// 				break;
		// 			case 299:
		// 				setPriceRange({
		// 					cost: 299,
		// 					emails: '500-1M'
		// 				});
		// 				break;
		// 		}
		// 		console.log(priceRange);
		// 		console.log(input.value)
		// 	});

	}

	useEffect(() => {



	}, [priceRange]);
	return (
		<div style={{ background: '#FFF' }}>

			<Typography variant='body1' style={{ background: '#f27021', color: 'white', fontWeight: 500, textAlign: 'center' }}>نسخة تجريبية</Typography>

			<HeaderNavBar />
			<div className="offcanvas offcanvas-end text-inverse" id="offcanvas-info" data-bs-scroll="true">
				<div className="offcanvas-header">
					<h3 className="text-white fs-30 mb-0">Mujaz</h3>
					<button type="button" style={{ border: 'none', color: 'black', background: 'transparent' }} className="btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"><CloseRoundedIcon color="black" /></button>
				</div>
				<div className="offcanvas-body">
					<div className="widget mb-8">
						<p>موجز، منصّة إرسال بريد إلكتروني جماعي، تُمكّنك من إرسال وإدارة الرسائل البريدية والإخبارية والإعلانية باللغة العربية.</p>
					</div>
					<div className="widget mb-8" style={{ direction: 'ltr', textAlign: 'right' }}>
						<h4 className="widget-title text-white mb-3">معلومات الاتصال</h4>
						<address> تركيا <br /> إسطنبول  </address>
						<a href="mailto:hello@mujaz.io">hello@mujaz.io</a><br /> <span>+9 0 535 593 8150</span>
					</div>
					<div className="widget mb-8">
						<h4 className="widget-title text-white mb-3">اعرف المزيد</h4>
						<ul className="list-unstyled">
							<li><a href="/terms-policies#terms-conditions" className="link-body" style={{ fontSize: '0.9rem' }}> شروط الاستخدام </a></li>
							<li><a href="/terms-policies#privacy-policy" className="link-body" style={{ fontSize: '0.9rem' }}>سياسة الخصوصية</a></li>
							<li><a href="/terms-policies#return-policy" className="link-body" style={{ fontSize: '0.9rem' }}>سياسة الاسترجاع</a></li>
						</ul>
					</div>
					<div className="widget">
						<h4 className="widget-title text-white mb-3">تابعونا</h4>
						<nav className="nav social social-white">
							<a href="#"><i className="uil uil-twitter"></i></a>
							<a href="#"><i className="uil uil-facebook-f"></i></a>
							{/* <a href="#"><i className="uil uil-dribbble"></i></a> */}
							<a href="#"><i className="uil uil-instagram"></i></a>
							<a href="#"><i className="uil uil-linkedin"></i></a>
							{/* <a href="#"><i className="uil uil-youtube"></i></a> */}
						</nav>
					</div>
				</div>
			</div>


			<div className="content-wrapper rtl-mujaz">
				<section className="wrapper bg-gradient-primary" id="home">
					<div className="container pt-10 pt-md-14 pb-8 text-center">
						<div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
							<div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
								<h2 className="display-1 mx-md-n5 mx-lg-0" style={{ textAlign: 'right' }}>أرسل الآن عبر موجز</h2>
								<TypeAnimation
									sequence={[
										'بسهولة', // Types 'One'
										1000, // Waits 1s
										'بسرعة', // Deletes 'One' and types 'Two'
										2000, // Waits 2s
										'بالعربية',
										3000,
									]}
									wrapper="h2"
									cursor={false}
									repeat={Infinity}
									style={{ fontSize: '2em', height: '50px', textAlign: 'right' }} />

								{/* <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0"></h1> */}
								<p className="lead fs-lg mb-7">توفّر منصّة موجز مجموعة أدوات تُساعِدك على إنشاء وإدارات وتطوير نشراتك البريدية. قم بتطوير أعمالك مع موجز.</p>

								<p><a href='#prices' className="btn btn-primary rounded-pill me-2">جربها الآن</a></p>
							</div>
							<div className="col-lg-7" style={{ marginTop: 0 }}>
								<figure><img className="w-auto" src="img/landingpage/banner.png" alt="" /></figure>
							</div>
						</div>
					</div>
				</section>



				<section className="wrapper bg-light" id='aboutus'>
					<div className="container py-14 py-md-16">
						<div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
							<div className="col-lg-6 position-relative order-lg-2">
								<div className="shape bg-dot primary rellax w-16 h-20" data-rellax-speed="1" style={{ top: '3rem', left: '5.5rem' }}></div>
								<div className="overlap-grid overlap-grid-2">
									<div className="item">
										<figure className="rounded "><img src="./img/landingpage/about-us.png" alt="" /></figure>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<img src="./assets/img/icons/lineal/megaphone.svg" className="svg-inject icon-svg icon-svg-md mb-4" alt="" />
								<h2 className="display-4 mb-3">من نحن</h2>
								<p className="lead fs-lg">موجز، منصّة إرسال بريد إلكتروني جماعي، تُمكّنك من إرسال وإدارة الرسائل البريدية والإخبارية والإعلانية باللغة العربية.</p>
								<p className="lead fs-lg">وذلك من خلال لوحة تحكّم متقدّمة وأدوات تحرير. ويمكّنك من إنشاء نشرة بريدية خلال فترة قصير. ومن أجل استهداف أكبر شريحة ممكنة تقدّم لك موجز أدوات خاصّة للتحليل والمراقبة.</p>
								<p className='lead fs-lg'>نحن هنا من أجل تعزيز المحتوى العربي، وإيصاله إلى الجمهور بطريقةٍ مختلفة وسهلة. كما وتصبح قراءة البريد الإلكتروني اليومي جزء من الروتين اليومي للبقاء على الإضطلاع بكل شيء يحدث من حولك.</p>
								<div className="row counter-wrapper gy-6">
									<div className="col-md-6">
										<div className="progressbar semi-circle orange" data-value="99"></div>
										<h6 className="mb-0 text-center">سرعة الاستجابة والجهوزية</h6>
									</div>
									<div className="col-md-6">
										<div className="progressbar semi-circle orange" data-value="97"></div>
										<h6 className="mb-0 text-center">معدل التسليم</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="wrapper bg-light">
					<div className="container py-14 py-md-1 text-center">
						<div className="row">
							<div className="col-md-12 col-lg-12">
								<h3 className="display-4 mb-4 px-xl-10">بعض إحصائيات البريد الإلكتروني لعام 2022</h3>
							</div>

						</div>

						<div className="position-relative mb-4">
							<div className="shape rounded-circle bg-soft-blue rellax w-16 h-16" data-rellax-speed="1" style={{ bottom: '-0.5rem', right: '-2.2rem', zIndex: 0 }}></div>
							<div className="shape bg-dot yellow rellax w-16 h-17" data-rellax-speed="1" style={{ top: '-0.5rem', left: '-2.5rem', zIndex: 0 }}></div>
							<div className="row gx-md-5 gy-5 text-center">
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>4.3</h1>
											<p className="mb-8">مليار مستخدم بريد إلكتروني نشط عالمياً</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>333.2</h1>
											<p className="mb-8">مليار  بريد إلكتروني يتم إرساله يومياً</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>99%</h1>
											<p className="mb-2">من مستخدمين يتحققون من بريدهم مرة واحد على الأقل</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="position-relative">
							<div className="row gx-md-5 gy-5 text-center">
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>21%</h1>
											<p className="mb-8">معدل متوسط لفتح البريد إلكتروني </p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>50%</h1>
											<p className="mb-2">معدل أعلى لفتح بريد إلكتروني يحتوي الموضوع في سطر واحد</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>42$</h1>
											<p className="mb-2">متوسط عائد على كل 1$ تنفقه على التسويق عبر البريد الإلكتروني</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</section>


				<section className="wrapper bg-light" id="specifics">
					<div className="container py-14 py-md-16">
						<div className="row text-center">
							<div className="col-md-12 col-lg-12">
								<h3 className="display-4 mb-9 px-xl-11">مميزات</h3>
							</div>

						</div>

						<div className="row gx-lg-8 gx-xl-12 gy-8">

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/user-interface-01.png" srcSet="./img/icon/user-interface-01.png 2x" alt="" />
										{/* <img src="./img/icons/lineal/target.svg" srcSet="./img/icons/lineal/target.svg 2x" className="svg-inject icon-svg icon-svg-sm text-aqua me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">واجهة مستخدم سهلة</h4>
										<p className="mb-0">تُمكن واجهة المستخدم من الوصول السهل إلى جميع الأدوات التي تساعدك بإنشاء نشرتك البريدية </p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/pencil-01.png" srcSet="./img/icon/pencil-01.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/medal.svg" className="svg-inject icon-svg icon-svg-sm text-yellow me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">نشرة بريدية قابلة للتخصيص</h4>
										<p className="mb-0">تخصيص شكل وألوان النشرة البريدية الخاصة بك لتكون جزء الهوية البصرية الخاصة بك</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/arabic.png" srcSet="./img/icon/arabic.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/clock-3.svg" className="svg-inject icon-svg icon-svg-sm text-red me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">دعم العربية</h4>
										<p className="mb-0">منصة كاملة باللغة العربية مخصصة للمستخدم من واجهة المستخدم حتى الدعم والتواصل</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/online-library.png" srcSet="./img/icon/online-library.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/check.svg" className="svg-inject icon-svg icon-svg-sm text-pink me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">مكتبة المحتوى</h4>
										<p className="mb-0">قم بحفظ المحتوى الخاص بكل في مكتبة واحد تُمكن من إرساله في وقت لاحق</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-md text-aqua ms-4" src="./img/icon/multi-users.png" srcSet="./img/icon/multi-users.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/shop-2.svg" className="svg-inject icon-svg icon-svg-sm text-green me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">دعم عدة محررين</h4>
										<p className="mb-0">بإمكانك إضافة أكثر من محرر وكاتب والعمل في نفس الوقت</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/tracking-01.png" srcSet="./img/icon/tracking-01.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/team.svg" className="svg-inject icon-svg icon-svg-sm text-purple me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">الإحصائيات والتحليل</h4>
										<p className="mb-0">أدوات تحليل ومراقبة للنشرة البريدية التي ترسلها مثل معدل الإرسال والاستلام والفتح</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="wrapper bg-light">
					<div className="container py-14 py-md-1">
						<div className="row text-center">
							<div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
								<h3 className="display-4 mb-10 px-xxl-11">خدمات موجز</h3>
							</div>

						</div>
						<div className="row gx-lg-8 gx-xl-12 gy-11 px-xxl-5 text-center d-flex align-items-end">
							<div className="col-lg-6">
								<div className="px-md-15 px-lg-3">
									<figure><img className="img-fluid-50" src="./img/landingpage/editing-proofreading.png" alt="" /></figure>
									<h3>التحرير والتدقيق اللغوي</h3>
									<p className="mb-2">توفر خدمات التحرير لدينا طريقة خالية من المتاعب لتزويد المستخدمين بالمحتوى. نوفر لك الوقت والجهد والمال من خلال إزالة عبء التوظيف وإدارة فرق التحرير الداخلية والاستعانة بفريق متخصص للكتابة والتحرير لدى موجز وقم بتواصل على مدار الساعة لإنشاء أفضل محتوى.</p>
								</div>

							</div>
							<div className="col-lg-6">
								<div className="px-md-15 px-lg-3">
									<figure><img className="img-fluid-50" src="./img/landingpage/sponsor.png" alt="" /></figure>
									<h3>برنامج الرعاية</h3>
									<p className="mb-8">يسمح لك برنامج الرعاية من كسب وزيادة إيراداتك عن طريق إضافة رعاة إلى رسالتك الإخبارية وفقًا لمنطقتك والجمهور المستهدف والصناعة ونوع المحتوى. نحن نعمل على تزويدك بالرعاة المناسبين بناءً على الإحصائيات والبيانات الخاصة بك.</p>
								</div>
							</div>
						</div>
					</div>
				</section>



				<section className="wrapper bg-light mt-9" id='prices'>
					<div className="container py-14 py-md-1">
						<div className="row gy-6" style={{ display: 'flex', justifyContent: 'space-around' }}>
							{/* <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
									<h3 className="display-4 mb-10 px-xxl-11">نماذج الأسعار</h3>
								</div> */}
							<div className="col-lg-4">
								<h2 className="display-4 mt-lg-18 mb-3">الباقات والأسعار</h2>
								<p className="lead fs-lg">نقدّم نموذجين من الباقات، لتختر المتناسب وحجم قائمتك البريدية</p>
								{/* <p>Enjoy a <a href="#" className="hover">free 30-day trial</a> and experience the full service. No credit card required!</p>
									<a href="#" className="btn btn-primary rounded-pill mt-2">See All Prices</a> */}
							</div>
							<div className="col-lg-7 pricing-wrapper">
								<div className="row gy-6 position-relative mt-5">
									<div className="shape bg-dot primary rellax w-16 h-18" data-rellax-speed="1" style={{ bottom: '-0.5rem', right: '-1.6rem' }}></div>
									<div className="shape rounded-circle bg-line red rellax w-18 h-18" data-rellax-speed="1" style={{ top: '-1rem', left: '-2rem' }}></div>
									<div className="col-md-6 popular">
										<div className="pricing card">
											<div className="card-body pb-12" style={{ paddingTop: '2rem' }}>
												<h3 className="card-title text-center mt-2">الحزمة المرنة</h3>

												<div className="prices text-dark mt-10">
													<div className="price price-show"><span className="price-currency">$</span><span className="price-value">0.70</span> <span className="price-duration" style={{ marginBottom: '0.29rem' }}>1000 بريد إلكتروني</span></div>
												</div>
												<div className="prices text-dark">
													<div className="price price-show mt-3"><AddCircleRoundedIcon color='primary' style={{ fontSize: '25px', marginTop: '5px' }} /><span className="price-currency">$</span><span className="price-value" style={{ fontSize: '1.6rem' }}>25</span> <span className="price-duration" style={{ marginBottom: '0.15rem' }}>شهر</span></div>
												</div>

												<ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8" style={{ paddingRight: 0 }}>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' /> <strong>0.70$</strong> لكل <strong>1000</strong> بريد إلكتروني</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' />حتى 50 ألف بريد إلكتروني شهري</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' /> دعم التخصيص والتضمين في موقعك</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' /> دعم فني وتقيني كل أيام الاسبوع</Typography></li>
												</ul>
												<a style={{ marginRight: '70px' }} href="/login" className="btn btn-primary rounded-pill">اشترك الآن</a>
											</div>

										</div>

									</div>

									<div className="col-md-6 popular">
										<div className="pricing card">
											<div className="card-body pb-12" style={{ paddingTop: '2rem' }}>
												<h3 className="card-title text-center mt-2">الحزمة الثابة</h3>
												<div className="prices text-dark mt-10">
													<div className="price price-show"><span className="price-currency" style={{ paddingTop: '0.3rem' }}>$</span><span className="price-value">{priceRange.cost}</span> <span className="price-duration" style={{ marginBottom: '0.29rem', fontSize: '0.9rem' }}>{priceRange.emails} بريد إلكتروني</span></div>
												</div>


												<input id="rangeInput" type="range" value={priceRange.cost} onChange={(event) => rangeInput(event.target.value)} min={0} max={324} />
												<ul className="icon-list bullet-bg bullet-soft-primary mt-6 mb-8" style={{ paddingRight: 0 }}>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' /> <strong>{priceRange.emails}</strong> بريد إلكتروني</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' />حتى 1 مليون بريد إلكتروني شهري</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' />دعم التخصيص والتضمين في موقعك</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' color='primary' />دعم فني وتقيني كل أيام الاسبوع</Typography></li>
												</ul>
												<a style={{ marginRight: '70px' }} href="/login" className="btn btn-primary rounded-pill">اشترك الآن</a>
											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</section>



			</div>



			<FooterNavBar />




		</div>
	);
};

export default Home;
